import React, { useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TextField,
	IconButton,
	TablePagination,
	Snackbar,
	Alert,
	Typography,
	Button,
	CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
	fetchActionPlanPrepareList,
	updateActionPlanCalculation,
	updateActionPlanStatus,
} from "../api";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import SignDSCFlow from "../common/SignDSCFlow";
import { Warning } from "@mui/icons-material";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const StyledTableCell = styled(TableCell)({
	padding: "8px 16px",
	height: "40px",
	fontSize: "0.875rem",
});

const StyledTableHeadCell = styled(TableCell)({
	padding: "8px 16px",
	height: "48px",
	fontSize: "0.875rem",
	backgroundColor: "rgb(243 244 246)",
	fontWeight: "600",
});

const StyledTableHeadCellMain = styled(TableCell)({
	backgroundColor: "#1976d2",
	color: "#fff",
	padding: "8px 16px",
	whiteSpace: "nowrap",
});

const StyledTableRow = styled(TableRow)({
	"&:hover": {
		backgroundColor: "rgb(243 244 246)",
	},
});

const WarningBox = styled(Box)({
	backgroundColor: "#fff3e0",
	border: "1px solid #ffb74d",
	borderRadius: "4px",
	padding: "12px",
	margin: "8px 0",
	display: "flex",
	alignItems: "center",
	gap: "12px",
});

const ActionPlanPrepareList = () => {
	const [data, setData] = useState([]);
	const [calculationList, setCalculationList] = useState({});
	const [editedValues, setEditedValues] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [headers, setHeaders] = useState([]);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [adminInfo, setAdminInfo] = useState(null);
	const [search, setSearch] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [gpLockStatus, setGpLockStatus] = useState(null);
	const [gpLockMessage, setGpLockMessage] = useState("");

	const navigate = useNavigate();

	const fetchData = async () => {
		const response = await fetchActionPlanPrepareList();
		if (response.status) {
			setData(response.results);
			setCalculationList(response.calculation_list);
			setAdminInfo(response.admin_info);
			setGpLockStatus(response.gp_lock_status);
			setGpLockMessage(response.gp_lock_message);
			if (response.results.length > 0) {
				setHeaders(
					Object.keys(response.results[0]).filter(
						(header) =>
							header !== "work_scategory_id" && header !== "min_selected_work"
					)
				);
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleInputChange = (id, value) => {
		setEditedValues({ ...editedValues, [id]: value });
	};

	const handleSave = async (id) => {
		try {
			setIsSaving(true);
			const noSelectedWork = editedValues[id];
			const response = await updateActionPlanCalculation(id, noSelectedWork);
			if (response.status) {
				setCalculationList(response.results);
				setSnackbar({
					open: true,
					message: "Updated successfully",
					severity: "success",
				});
				setData((prevData) =>
					prevData.map((item) =>
						item.work_scategory_id === id
							? { ...item, no_selected_work: noSelectedWork }
							: item
					)
				);
				setEditedValues((prev) => {
					const newValues = { ...prev };
					delete newValues[id];
					return newValues;
				});
			}
		} catch (error) {
			setSnackbar({
				open: true,
				message: "Failed to update",
				severity: "error",
			});
		} finally {
			setIsSaving(false);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSigningAllowed = () => {
		if (!calculationList) return false;

		// Check both budget condition and show_sign_dsc flag
		return calculationList?.show_sign_dsc && adminInfo;
	};

	const handleDscSuccess = async () => {
		try {
			setIsLoading(true);
			const response = await updateActionPlanStatus();
			if (response.status) {
				setSnackbar({
					open: true,
					message: response.message || "Action plan updated successfully",
					severity: "success",
				});
				setEditedValues({});
				await fetchData();
			} else {
				setSnackbar({
					open: true,
					message: response.message || "Failed to update action plan status",
					severity: "error",
				});
			}
		} catch (error) {
			console.error("Error updating action plan status:", error);
			setSnackbar({
				open: true,
				message: "Failed to update action plan status",
				severity: "error",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const filteredData = data.filter((row) => {
		return Object.values(row).some((value) =>
			String(value).toLowerCase().includes(search.toLowerCase())
		);
	});

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mb: 2,
				}}
			>
				<Typography
					variant="h6"
					component="div"
					className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-0 xs:pt-2 pt-1"
				>
					Action Prepare List
				</Typography>
				<div className="flex space-x-2">
					<Button
						variant="contained"
						color="info"
						onClick={() => navigate("/core/actionplan-work-list")}
						sx={{ marginRight: 2 }}
					>
						View
					</Button>
					{isSigningAllowed() && adminInfo && (
						<SignDSCFlow
							adminInfo={adminInfo}
							onSuccess={handleDscSuccess}
							autoOpen={false}
							disabled={isLoading}
							confirmationMessage={calculationList?.confirmation_message}
						/>
					)}
				</div>
			</Box>

			{gpLockStatus === "1" ? (
				<WarningBox>
					<Warning color="warning" />
					<Typography>{gpLockMessage}</Typography>
				</WarningBox>
			) : (
				<>
					{calculationList?.approval_message && (
						<WarningBox>
							<Warning color="warning" />
							<Typography>{calculationList.approval_message}</Typography>
						</WarningBox>
					)}

					<TableContainer component={Paper} sx={{ marginBottom: 2 }}>
						<Table size="small">
							<TableHead>
								<StyledTableRow>
									{calculationList &&
										Object.entries(calculationList)
											.filter(
												([key]) =>
													![
														"show_sign_dsc",
														"approval_message",
														"confirmation_message",
													].includes(key)
											)
											.map(([key]) => (
												<StyledTableHeadCell key={key}>
													{key.replace(/_/g, " ").toUpperCase()}
												</StyledTableHeadCell>
											))}
								</StyledTableRow>
							</TableHead>
							<TableBody>
								<StyledTableRow>
									{calculationList &&
										Object.entries(calculationList)
											.filter(
												([key]) =>
													![
														"show_sign_dsc",
														"approval_message",
														"confirmation_message",
													].includes(key)
											)
											.map(([key, value], index) => (
												<StyledTableCell key={index}>{value}</StyledTableCell>
											))}
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>

					<div className="flex justify-between items-center mb-2 mt-2 px-4">
						<div className="ml-auto">
							<TextField
								variant="outlined"
								placeholder="Search…"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								className="w-full sm:w-64"
							/>
						</div>
					</div>

					<StyledTableContainer component={Paper}>
						<Table size="small" className="pb-4">
							<TableHead>
								<StyledTableRow>
									{headers.map((header) => (
										<StyledTableHeadCellMain key={header}>
											{header.replace(/_/g, " ").toUpperCase()}
										</StyledTableHeadCellMain>
									))}
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{filteredData
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => (
										<StyledTableRow key={row.work_scategory_id}>
											{headers.map((header) => (
												<StyledTableCell
													key={`${row.work_scategory_id}-${header}`}
												>
													{header === "no_selected_work" ? (
														<Box sx={{ display: "flex", alignItems: "center" }}>
															<TextField
																size="small"
																type="number"
																value={
																	editedValues[row.work_scategory_id] !==
																	undefined
																		? editedValues[row.work_scategory_id]
																		: row[header] || ""
																}
																onChange={(e) => {
																	const value = e.target.value;
																	handleInputChange(
																		row.work_scategory_id,
																		value
																	);
																}}
																inputProps={{
																	min: row.min_selected_work,
																	max: row.total_works_comes_from_priority,
																	style: { padding: "4px 8px" },
																}}
																sx={{ width: "100px" }}
																error={
																	editedValues[row.work_scategory_id] !==
																		undefined &&
																	(editedValues[row.work_scategory_id] === "" ||
																		parseInt(
																			editedValues[row.work_scategory_id]
																		) < row.min_selected_work ||
																		parseInt(
																			editedValues[row.work_scategory_id]
																		) > row.total_works_comes_from_priority)
																}
																helperText={
																	editedValues[row.work_scategory_id] !==
																		undefined &&
																	(editedValues[row.work_scategory_id] === "" ||
																		parseInt(
																			editedValues[row.work_scategory_id]
																		) < row.min_selected_work ||
																		parseInt(
																			editedValues[row.work_scategory_id]
																		) > row.total_works_comes_from_priority)
																		? `Value must be between ${row.min_selected_work} and ${row.total_works_comes_from_priority}`
																		: ""
																}
															/>
															<IconButton
																size="small"
																onClick={() =>
																	handleSave(row.work_scategory_id)
																}
																disabled={
																	editedValues[row.work_scategory_id] ===
																		undefined ||
																	editedValues[row.work_scategory_id] === "" ||
																	parseInt(
																		editedValues[row.work_scategory_id]
																	) < row.min_selected_work ||
																	parseInt(
																		editedValues[row.work_scategory_id]
																	) > row.total_works_comes_from_priority ||
																	isSaving
																}
																color="primary"
																className="pl-2"
															>
																{isSaving &&
																editedValues[row.work_scategory_id] !==
																	undefined ? (
																	<CircularProgress size={20} />
																) : (
																	<SaveIcon fontSize="small" />
																)}
															</IconButton>
														</Box>
													) : (
														row[header]
													)}
												</StyledTableCell>
											))}
										</StyledTableRow>
									))}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[20, 50, 100]}
							component="div"
							count={filteredData.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</StyledTableContainer>

					<Snackbar
						open={snackbar.open}
						autoHideDuration={3000}
						onClose={() => setSnackbar({ ...snackbar, open: false })}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<Alert
							onClose={() => setSnackbar({ ...snackbar, open: false })}
							severity={snackbar.severity}
						>
							{snackbar.message}
						</Alert>
					</Snackbar>

					{isLoading && (
						<Box
							sx={{
								position: "fixed",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								backgroundColor: "rgba(255, 255, 255, 0.7)",
								zIndex: 9999,
							}}
						>
							<CircularProgress />
						</Box>
					)}
				</>
			)}
		</Box>
	);
};

export default ActionPlanPrepareList;
