import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import Cookies from "js-cookie";

interface SidebarMenuItem {
	sidebar_menu_id: string;
	sidebar_id: string | null;
	sidebar_menu_name: string;
	sidebar_menu_icon: string;
	sidebar_menu_link: string;
}

interface SidebarItem {
	sidebar_id: string;
	sidebar_name: string;
	sidebar_link: string;
	sidebar_icon: string;
	count: number;
	menu_details: SidebarMenuItem[] | "";
}

interface Role {
	role_id: string;
	admin_id: string;
	admin_role_name: string;
	designation_name: string;
}

export async function getSidebar(): Promise<SidebarItem[]> {
	try {
		const url = `${DOMAIN_URL}/api/sidebar`;
		// Get fresh cookie values
		const adminId = Cookies.get("admin_id");
		const roleId = Cookies.get("role_id");
		const budgetYear = Cookies.get("budget_year");

		if (!adminId || !roleId || !budgetYear) {
			console.warn(
				"Missing required authentication cookies. Refreshing page..."
			);
			window.location.reload();
			return []; // Return empty array to satisfy the return type
		}

		const headers = {
			...getAuthHeaders(),
			Adminid: adminId,
			Roleid: roleId,
			Budgetyear: budgetYear,
		};

		const response = await fetch(url, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.log("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data = await response.json();

		if (!data.status) {
			console.log("Failed to fetch sidebar data");
		}

		return data.results;
	} catch (error) {
		console.log("Error fetching sidebar data: check N/W error");
		return [];
		// throw error;
	}
}

interface UpdateWorkStatusParams {
	work_demand_id: number;
	status_type: "ACCEPT" | "REJECT";
	reject_reason_id?: string;
	reason_info?: string;
	url: string;
	work_name?: string;
}

interface UpdateWorkStatusResponse {
	status: boolean;
	message: string;
}

export async function updateWorkStatus(
	params: UpdateWorkStatusParams
): Promise<UpdateWorkStatusResponse> {
	try {
		const url = `${DOMAIN_URL}/${params.url}`;

		const inputData = JSON.stringify({
			work_demand_id: params.work_demand_id,
			status_type: params.status_type,
			reject_reason_id:
				params.status_type === "REJECT" ? params.reject_reason_id : "",
			reason_info: params.reason_info || "",
			work_name: params.work_name || "",
		});

		const formData = new URLSearchParams();
		formData.append("input_data", inputData);

		const response = await fetch(url, {
			method: "POST",
			headers: getAuthHeaders(),
			body: formData,
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: UpdateWorkStatusResponse = await response.json();

		if (!data.status) {
			throw new Error(data.message || "Failed to update work status");
		}

		return data;
	} catch (error) {
		console.error("Error updating work status:", error);
		throw error;
	}
}

interface UpdateAllApWorkStatusParams {
	work_demands: number[];
	status_type: "ACCEPT" | "REJECT";
	reject_reason_id?: string;
	reason_info?: string;
	url?: string;
}

interface UpdateAllApWorkStatusResponse {
	status: boolean;
	message: string;
}

export async function updateAllApWorkStatus(
	params: UpdateAllApWorkStatusParams
): Promise<UpdateAllApWorkStatusResponse> {
	try {
		const url = `${DOMAIN_URL}/${
			params.url ? params.url : "update-all-apwork-status"
		}`;

		const inputData = JSON.stringify({
			work_demands: params.work_demands,
			status_type: params.status_type,
			reject_reason_id:
				params.status_type === "REJECT" ? params.reject_reason_id : "",
			reason_info: params.reason_info || "",
		});

		const formData = new URLSearchParams();
		formData.append("input_data", inputData);

		const response = await fetch(url, {
			method: "POST",
			headers: getAuthHeaders(),
			body: formData,
		});

		if (!response.ok) {
			const errorText = await response.text();
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: UpdateAllApWorkStatusResponse = await response.json();

		if (!data.status) {
			throw new Error(data.message || "Failed to update all AP work status");
		}

		return data;
	} catch (error) {
		throw error;
	}
}

interface WorkReason {
	reject_reason_id: string;
	reason_name: string;
}

interface WorkReasonResponse {
	key: string;
	status: boolean;
	results: WorkReason[];
}

export async function fetchWorkReasonList(
	module_name: string
): Promise<WorkReason[]> {
	try {
		const formData = new URLSearchParams();
		formData.append("input_data", JSON.stringify({ module_name }));

		const response = await fetch(`${DOMAIN_URL}/work-reason-list`, {
			method: "POST", // Change to POST if needed
			headers: getAuthHeaders(),
			body: formData,
		});

		if (!response.ok) throw new Error("Failed to fetch work reason list");
		const data: WorkReasonResponse = await response.json();
		if (!data.status) throw new Error("Failed to fetch work reason list");
		return data.results;
	} catch (error) {
		console.error("Error fetching work reason list:", error);
		throw error;
	}
}

interface PremeasurementInfo {
	work_demand_id: string;
	work_id: string;
	work_type_id: string;
	work_type_name: string;
	work_title: string;
	amount_type: string;
	wage: string;
	material: string;
	description: string;
	work_image: string;
	full_name: string | null;
	phone_number: string;
	status_name: string;
	status_id: string;
	gQuestion: {
		work_group_id: string;
		group_question_id: string;
		measurement_id: string;
		work_id: string;
		question: string;
		field_type: string;
		unit_type: string | null;
		mandatory: string;
		select_list: [] | null | string;
	}[];
	iQuestion: {
		work_question_id: string;
		measurement_id: string;
		work_id: string;
		question: string;
		field_type: string;
		unit_type: string;
		mandatory: string;
		select_list: [] | null | string;
	}[];
}

interface StartWorkPremeasurementResponse {
	key: string;
	status: boolean;
	img_path?: string;
	results?: PremeasurementInfo;
}

export async function startWorkPremeasurement(
	work_demand_id: string
): Promise<StartWorkPremeasurementResponse> {
	try {
		const url = `${DOMAIN_URL}/work-premeasurment-info/${work_demand_id}`;

		const response = await fetch(url, {
			method: "GET",
			headers: getAuthHeaders(),
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: StartWorkPremeasurementResponse = await response.json();

		if (!data.status) {
			throw new Error("Failed to start work premeasurement");
		}

		return data;
	} catch (error) {
		console.error("Error starting work premeasurement:", error);
		throw error;
	}
}

interface CompletePremeasurementParams {
	work_demand_id: number;
	gQuestion?: {
		work_group_id: string;
		group_question_id: string;
		measurement_id: string;
		selected_value: string;
		number: string;
		length: string;
		width: string;
		depth: string;
		text_value: string;
	}[];
	iQuestion?: {
		work_question_id: string;
		group_question_id: string;
		measurement_id: string;
		selected_value: string;
		number: string;
		length: string;
		width: string;
		depth: string;
		text_value: string;
	}[];
}

interface CompletePremeasurementResponse {
	status: boolean;
	message: string;
}

export async function completePreMeasurement(
	params: CompletePremeasurementParams,
	uploadedPhoto?: File // Add uploadedPhoto as an optional parameter
): Promise<CompletePremeasurementResponse> {
	try {
		const url = `${DOMAIN_URL}/complete-pre-measurement`;
		const formData = new FormData(); // Use FormData to handle file uploads

		// Append parameters to FormData
		formData.append("input_data", JSON.stringify(params));
		if (uploadedPhoto) {
			formData.append("uploaded_photo", uploadedPhoto); // Append the uploaded photo
		}

		const response = await fetch(url, {
			method: "POST",
			headers: getAuthHeaders(false),
			body: formData,
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}
		const data: CompletePremeasurementResponse = await response.json();

		if (!data.status) {
			throw new Error(data.message || "Failed to complete pre-measurement");
		}

		return data;
	} catch (error) {
		console.error("Error completing pre-measurement:", error);
		throw error;
	}
}

interface WorkType {
	work_type_id: string;
	work_type_name: string;
	status_name: string;
	status_id: string;
}

interface WorkTypeResponse {
	key: string;
	status: boolean;
	results: WorkType[];
}

export async function fetchWorkTypes(): Promise<WorkType[]> {
	try {
		const url = `${DOMAIN_URL}/work-types`;

		const response = await fetch(url, {
			method: "GET",
			headers: getAuthHeaders(),
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: WorkTypeResponse = await response.json();

		if (!data.status) {
			throw new Error("Failed to fetch work types");
		}

		return data.results;
	} catch (error) {
		console.error("Error fetching work types:", error);
		throw error;
	}
}

interface ActionPlanUploadResponse {
	key: string;
	status: boolean;
}

export const uploadActionPlan = async (
	file: File,
	remarks: string,
	budgetYearId: string
): Promise<ActionPlanUploadResponse> => {
	function formatDateToYYYYMMDD(dateString: string): string {
		const date = new Date(dateString);
		return date.toISOString().split("T")[0];
	}
	const url = `${DOMAIN_URL}/actionplan-upload`;

	const formData = new FormData();
	formData.append(
		"input_data",
		JSON.stringify({
			remarks: remarks,
			commencement_date: formatDateToYYYYMMDD(budgetYearId),
		})
	);
	formData.append("upload_file", file);

	try {
		const response = await fetch(url, {
			method: "POST",
			body: formData,
			headers: getAuthHeaders(false),
		});

		if (!response.ok) {
			throw new Error("File upload failed");
		}

		const result: ActionPlanUploadResponse = await response.json();
		return result;
	} catch (error) {
		console.error("Error during file upload:", error);
		throw error;
	}
};

// ... existing imports and functions ...

interface GramSabhaFileStatusParams {
	ap_pdf_id: number;
	status_type: "ACCEPT" | "REJECT";
	reject_reason_id?: string;
	reason_info: string;
}

interface GramSabhaFileStatusResponse {
	status: boolean;
	message: string;
}

export async function gramSabhaFileStatus(
	params: GramSabhaFileStatusParams
): Promise<GramSabhaFileStatusResponse> {
	try {
		const url = `${DOMAIN_URL}/update-gsfile-status`;

		const inputData = JSON.stringify({
			ap_pdf_id: params.ap_pdf_id,
			status_type: params.status_type,
			reject_reason_id:
				params.status_type === "REJECT" ? params.reject_reason_id : "",
			reason_info: params.reason_info,
		});

		const formData = new URLSearchParams();
		formData.append("input_data", inputData);

		const response = await fetch(url, {
			method: "POST",
			headers: getAuthHeaders(),
			body: formData,
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: GramSabhaFileStatusResponse = await response.json();

		if (!data.status) {
			throw new Error(
				data.message || "Failed to update Gram Sabha file status"
			);
		}

		return data;
	} catch (error) {
		console.error("Error updating Gram Sabha file status:", error);
		throw error;
	}
}

export const deleteActionPlan = async (apPdfId: string) => {
	const response = await fetch(`${DOMAIN_URL}/actionplan-delete/${apPdfId}`, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});

	if (!response.ok) {
		throw new Error("Failed to delete action plan");
	}

	return response.json();
};

interface BudgetYear {
	budget_year_id: string;
	budget_year: string;
}

interface BudgetYearResponse {
	key: string;
	status: boolean;
	admin_name: string;
	admin_link: string;
	results: BudgetYear[];
}

export async function fetchBudgetYears(): Promise<BudgetYearResponse> {
	try {
		const url = `${DOMAIN_URL}/budget-year`;

		const response = await fetch(url, {
			method: "GET",
			headers: getAuthHeaders(),
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: BudgetYearResponse = await response.json();

		if (!data.status) {
			throw new Error("Failed to fetch budget years");
		}

		return data;
	} catch (error) {
		console.error("Error fetching budget years:", error);
		throw error;
	}
}

interface UserWorkListResponse {
	key: string;
	status: boolean;
	img_path: string;
	results: any[];
}

export async function fetchUserWorkList(phoneNumber: string): Promise<any[]> {
	try {
		const url = `${DOMAIN_URL}/user-work-list`;
		const formData = new URLSearchParams();
		formData.append(
			"input_data",
			JSON.stringify({ phone_number: phoneNumber })
		);

		const response = await fetch(url, {
			method: "POST",
			headers: getAuthHeaders(),
			body: formData,
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();

		if (!data.status) {
			throw new Error("Failed to fetch user work list");
		}

		return data;
	} catch (error) {
		console.error("Error fetching user work list:", error);
		throw error;
	}
}

interface UploadCommencementPhotoResponse {
	status: boolean;
	message: string;
}

export async function uploadCommencementPhoto(
	file: File,
	remarks: string
): Promise<UploadCommencementPhotoResponse> {
	try {
		const url = `${DOMAIN_URL}/upload-commencement-photo`;
		const formData = new FormData();

		// Append parameters to FormData
		formData.append("input_data", JSON.stringify({ remarks }));
		formData.append("uploaded_photo", file);

		const response = await fetch(url, {
			method: "POST",
			headers: getAuthHeaders(false), // false to exclude Content-Type for FormData
			body: formData,
		});

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: UploadCommencementPhotoResponse = await response.json();

		if (!data.status) {
			throw new Error(data.message || "Failed to upload photo");
		}

		return data;
	} catch (error) {
		console.error("Error uploading photo:", error);
		throw error;
	}
}

export async function fetchActionPlanPrepareList() {
	const response = await fetch(`${DOMAIN_URL}/actionplan-prepare-list`, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
}

export async function updateActionPlanCalculation(
	work_scategory_id: string,
	no_selected_work: number
) {
	const formData = new URLSearchParams();
	formData.append(
		"input_data",
		JSON.stringify({ work_scategory_id, no_selected_work })
	);

	const response = await fetch(`${DOMAIN_URL}/actionplan-calculation`, {
		method: "POST",
		headers: getAuthHeaders(),
		body: formData,
	});
	return response.json();
}

export async function updateActionPlanStatus() {
	const response = await fetch(`${DOMAIN_URL}/update-actionplan-status`, {
		method: "POST",
		headers: getAuthHeaders(),
	});

	if (!response.ok) {
		throw new Error("Failed to update action plan status");
	}

	return response.json();
}
