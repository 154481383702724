import { useState, useEffect, useCallback } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import Cookies from "js-cookie";

const LocationSelector = ({ onGPSelect }) => {
	const [zillaPanchayats, setZillaPanchayats] = useState([]);
	const [talukPanchayats, setTalukPanchayats] = useState([]);
	const [gramPanchayats, setGramPanchayats] = useState([]);
	const [currentRole, setCurrentRole] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [formData, setFormData] = useState({
		zp_id: "",
		tp_id: "",
		gp_id: "",
	});

	const fetchDataAsync = useCallback(async (url, setter) => {
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Network response was not ok");

			const data = await response.json();
			if (data.status) {
				setter(data.results);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}, []);

	const fetchCurrentRole = useCallback(async () => {
		const roleId = Cookies.get("role_id");
		if (!roleId) return null;

		try {
			const response = await fetch(`${DOMAIN_URL}/user-roles`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Failed to fetch user roles");

			const data = await response.json();
			if (data.status && Array.isArray(data.results)) {
				const role =
					data.results.find((role) => role.role_id === roleId) || null;
				setCurrentRole(role);
				return role;
			}
		} catch (error) {
			console.error("Error fetching current role:", error);
		}
		return null;
	}, []);

	const initializeBasedOnUserRole = useCallback(async () => {
		setIsLoading(true);
		try {
			const role = await fetchCurrentRole();
			if (!role) {
				// If no role, just fetch ZP list
				await fetchDataAsync(`${DOMAIN_URL}/zp-list`, setZillaPanchayats);
				setIsLoading(false);
				return;
			}

			if (role.gp_id) {
				// GP level user
				const [zpData, tpData, gpData] = await Promise.all([
					fetch(`${DOMAIN_URL}/zp-list`, { headers: getAuthHeaders() }).then(
						(r) => r.json()
					),
					fetch(`${DOMAIN_URL}/tp-list/${role.zp_id}`, {
						headers: getAuthHeaders(),
					}).then((r) => r.json()),
					fetch(`${DOMAIN_URL}/gp-list/${role.tp_id}`, {
						headers: getAuthHeaders(),
					}).then((r) => r.json()),
				]);

				setZillaPanchayats(zpData.results);
				setTalukPanchayats(tpData.results);
				setGramPanchayats(gpData.results);
				setFormData({
					zp_id: role.zp_id,
					tp_id: role.tp_id,
					gp_id: role.gp_id,
				});
				onGPSelect(role.gp_id);
			} else if (role.tp_id) {
				const [zpData, tpData] = await Promise.all([
					fetch(`${DOMAIN_URL}/zp-list`, { headers: getAuthHeaders() }).then(
						(r) => r.json()
					),
					fetch(`${DOMAIN_URL}/tp-list/${role.zp_id}`, {
						headers: getAuthHeaders(),
					}).then((r) => r.json()),
				]);

				setZillaPanchayats(zpData.results);
				setTalukPanchayats(tpData.results);
				fetchDataAsync(
					`${DOMAIN_URL}/gp-list/${role.tp_id}`,
					setGramPanchayats
				);
				setFormData({
					zp_id: role.zp_id,
					tp_id: role.tp_id,
					gp_id: "",
				});
			} else if (role.zp_id) {
				const [zpData, tpData] = await Promise.all([
					fetch(`${DOMAIN_URL}/zp-list`, { headers: getAuthHeaders() }).then(
						(r) => r.json()
					),
					fetch(`${DOMAIN_URL}/tp-list/${role.zp_id}`, {
						headers: getAuthHeaders(),
					}).then((r) => r.json()),
				]);

				setZillaPanchayats(zpData.results);
				setTalukPanchayats(tpData.results);
				setFormData({
					zp_id: role.zp_id,
					tp_id: "",
					gp_id: "",
				});
			} else {
				const zpResponse = await fetch(`${DOMAIN_URL}/zp-list`, {
					headers: getAuthHeaders(),
				});
				const zpData = await zpResponse.json();
				setZillaPanchayats(zpData.results);
			}
		} catch (error) {
			console.error("Error initializing data:", error);
		} finally {
			setIsLoading(false);
		}
	}, [fetchCurrentRole, fetchDataAsync]);

	useEffect(() => {
		initializeBasedOnUserRole();
	}, []);

	const handleZillaPanchayatChange = (e) => {
		const zpId = e.target.value;
		setFormData((prev) => ({ ...prev, zp_id: zpId, tp_id: "", gp_id: "" }));
		if (zpId) {
			fetchDataAsync(`${DOMAIN_URL}/tp-list/${zpId}`, setTalukPanchayats);
			setGramPanchayats([]);
		}
	};

	const handleTalukPanchayatChange = (e) => {
		const tpId = e.target.value;
		setFormData((prev) => ({ ...prev, tp_id: tpId, gp_id: "" }));
		if (tpId) {
			fetchDataAsync(`${DOMAIN_URL}/gp-list/${tpId}`, setGramPanchayats);
		}
	};

	const handleGramPanchayatChange = (e) => {
		const gpId = e.target.value;
		setFormData((prev) => ({ ...prev, gp_id: gpId }));
		if (gpId) {
			onGPSelect(gpId);
		}
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-64">
				<CircularProgress />
			</div>
		);
	}

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
			<Autocomplete
				id="zillaPanchayat"
				options={zillaPanchayats}
				getOptionLabel={(option) => option.zp_name || ""}
				value={
					zillaPanchayats.find((zp) => zp.zp_id === formData.zp_id) || null
				}
				onChange={(event, newValue, reason) =>
					handleZillaPanchayatChange({
						target: { value: newValue?.zp_id || "" },
					})
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Zilla Panchayat"
						fullWidth
						margin="normal"
						required
					/>
				)}
				renderOption={(props, option) => (
					<li {...props} key={`zp-${option.zp_id}`}>
						{option.zp_name}
					</li>
				)}
				disabled={!!(currentRole && currentRole.zp_name)}
			/>

			<Autocomplete
				id="talukPanchayat"
				options={talukPanchayats}
				getOptionLabel={(option) => option.tp_name || ""}
				value={
					talukPanchayats.find((tp) => tp.tp_id === formData.tp_id) || null
				}
				onChange={(event, newValue, reason) =>
					handleTalukPanchayatChange({
						target: { value: newValue?.tp_id || "" },
					})
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Taluk Panchayat"
						fullWidth
						margin="normal"
						required
					/>
				)}
				renderOption={(props, option) => (
					<li {...props} key={`tp-${option.tp_id}`}>
						{option.tp_name}
					</li>
				)}
				disabled={!formData.zp_id || !!(currentRole && currentRole.tp_name)}
			/>

			<Autocomplete
				id="gramPanchayat"
				options={gramPanchayats}
				getOptionLabel={(option) => option.gp_name || ""}
				value={gramPanchayats.find((gp) => gp.gp_id === formData.gp_id) || null}
				onChange={(event, newValue, reason) =>
					handleGramPanchayatChange({
						target: { value: newValue?.gp_id || "" },
					})
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Gram Panchayat"
						fullWidth
						margin="normal"
						required
					/>
				)}
				renderOption={(props, option) => (
					<li {...props} key={`gp-${option.gp_id}`}>
						{option.gp_name}
					</li>
				)}
				disabled={!formData.tp_id || !!(currentRole && currentRole.gp_name)}
			/>
		</div>
	);
};

export default LocationSelector;
