import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockIcon from "@mui/icons-material/Lock";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Cookies from "js-cookie";
import RoleSelect from "./RoleSelect";
import YearSelect from "./YearSelect";
import { translations, getCurrentLanguage } from "../../utils/translations";

const SignInSignUpLink = () => {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const Authenticationkey = Cookies.get("Authenticationkey");
		setIsAuthenticated(Authenticationkey);
	}, [location]);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		// Clear only the specified cookies
		const cookiesToClear = [
			"Authenticationkey",
			"budget_year",
			"role_id",
			"admin_id",
		];
		cookiesToClear.forEach((cookieName) => {
			Cookies.remove(cookieName);
		});

		// Close the menu
		handleMenuClose();

		// Navigate to home page
		navigate("/");
	};

	const handleYearChange = (selectedYearId) => {
		// Set the budget_year cookie
		Cookies.set("budget_year", selectedYearId, {
			secure: true,
		});

		// Reload the page
		window.location.reload();
	};

	return (
		<div className="flex flex-col xs:flex-row items-center space-y-2 xs:space-y-0 xs:space-x-2">
			{!isAuthenticated ? (
				<Link
					className="nav-item text-inherit hover:text-opacity-80 transition-colors duration-200"
					to="/auth-login"
					activeclassname="active"
				>
					<span className="nav-link">{t.sign_in}</span>
				</Link>
			) : (
				<>
					{!location.pathname.includes("core") ? null : (
						<div className="flex flex-col xs:flex-row items-center space-y-2 xs:space-y-0 xs:space-x-2">
							<YearSelect />
							<RoleSelect />
						</div>
					)}
					<IconButton
						onClick={handleMenuOpen}
						className="text-inherit hover:text-opacity-80"
					>
						<AccountCircleIcon />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						PaperProps={{
							className:
								"bg-white dark:bg-slate-900 text-gray-900 dark:text-white",
						}}
					>
						<MenuItem
							onClick={handleMenuClose}
							component={Link}
							to="/core/dashboard"
						>
							<DashboardIcon className="mr-2" />
							Dashboard
						</MenuItem>
						<MenuItem
							onClick={handleMenuClose}
							component={Link}
							to="/auth-reset-password"
						>
							<LockIcon className="mr-2" />
							Change Password
						</MenuItem>
						<MenuItem onClick={handleLogout}>
							<ExitToAppIcon className="mr-2" />
							Logout
						</MenuItem>
					</Menu>
				</>
			)}
		</div>
	);
};

export default SignInSignUpLink;
