import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	TableSortLabel,
	TextField,
	Checkbox,
	IconButton,
	Typography,
	Switch,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Snackbar,
	Alert,
	DialogContentText,
	Tooltip,
} from "@mui/material";

import { styled } from "@mui/system";
import {
	Edit as EditIcon,
	Save as SaveIcon,
	Info as InfoIcon,
	History as HistoryIcon,
	SettingsBackupRestore,
} from "@mui/icons-material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import RaiseNewDemand from "component/RaiseNewDemand";
import {
	updateWorkStatus,
	fetchWorkReasonList,
	startWorkPremeasurement,
	gramSabhaFileStatus,
} from "../api";
import PreMeasurementForm from "./PreMeasurementForm";
import FileUploadButton from "component/FileUploadbutton";
import ActionPlanProceeding from "./ActionPlanProceeding";
import ActionPlanPriority from "./ActionPlanPriority";
import { getCurrentLanguage, translations } from "utils/translations";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import RaiseDemandWork from "pages/RaiseDemandWork";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const ContentArea = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [search, setSearch] = useState("");
	const [editMode, setEditMode] = useState({});
	const [selected, setSelected] = useState([]);
	const [openRejectDialog, setOpenRejectDialog] = useState(false);
	const [rejectReasons, setRejectReasons] = useState([]);
	const [selectedReasonId, setSelectedReasonId] = useState("");
	const [otherRejectReason, setOtherRejectReason] = useState("");
	const [rejectingWorkId, setRejectingWorkId] = useState(null);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [refreshTrigger, setRefreshTrigger] = useState(0);
	const [preMeasurementData, setPreMeasurementData] = useState(null);
	const [showPreMeasurement, setShowPreMeasurement] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
	const [acceptingWorkId, setAcceptingWorkId] = useState(null);
	const [imgPath, setImgPath] = useState("");
	const [workName, setWorkName] = useState("");
	const [workNameError, setWorkNameError] = useState("");

	const pathSegments = location.pathname.split("/");
	const fetchUrlSegment = pathSegments[pathSegments.length - 1];

	const isPreMeasurementList = fetchUrlSegment === "pre-measurement-list";
	const isPartialMeasurementList =
		fetchUrlSegment === "partial-measurement-list";
	const isActionPlanPriority = fetchUrlSegment === "actionplan-priority";
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	useEffect(() => {
		// Reset states and fetch new data when location changes or refreshTrigger updates
		setData(null);
		setIsLoading(true);
		setEditMode({});
		setSelected([]);
		setShowPreMeasurement(false);
		setPreMeasurementData(null);
		setSnackbar({ open: false, message: "", severity: "success" });
		setSearch("");

		const fetchData = async () => {
			if (fetchUrlSegment !== "raise-new-demand") {
				try {
					const fetchUrl = `${DOMAIN_URL}/${fetchUrlSegment}`;
					const response = await fetch(fetchUrl, {
						method: "GET",
						headers: getAuthHeaders(),
					});
					if (!response.ok) throw new Error("Network response was not ok");
					const fetchedData = await response.json();
					setData(fetchedData);
					if (fetchedData.img_path) {
						setImgPath(fetchedData.img_path);
					}
					if (fetchedData.results && fetchedData.results.length > 0) {
						setOrderBy(Object.keys(fetchedData.results[0])[0]);
					}
				} catch (error) {
					console.error("Error fetching data:", error);
					setData(null);
				} finally {
					setIsLoading(false);
				}
			} else {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [location.pathname, refreshTrigger, fetchUrlSegment]);

	const handlePriorityUpdate = () => {
		setRefreshTrigger((prev) => prev + 1);
	};

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearch = (event) => {
		setSearch(event.target.value);
	};

	const handleEdit = (index) => {
		setEditMode((prev) => ({ ...prev, [index]: !prev[index] }));
	};

	const handleSave = (index) => {
		setEditMode((prev) => ({ ...prev, [index]: false }));
		// Here you can handle save logic (e.g., API call to save changes)
	};

	const handleInputChange = (index, field, value) => {
		setData((prevData) => ({
			...prevData,
			results: prevData.results.map((row, i) =>
				i === index ? { ...row, [field]: value } : row
			),
		}));
	};

	const handleSwitchChange = (index, checked) => {
		setData((prevData) => ({
			...prevData,
			results: prevData.results.map((row, i) =>
				i === index
					? { ...row, status_name: checked ? "ACTIVE" : "INACTIVE" }
					: row
			),
		}));
		setEditMode((prev) => ({ ...prev, [index]: true }));
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = data.results.map((n, i) => i);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleCheckboxClick = (event, index) => {
		event.stopPropagation();
		const selectedIndex = selected.indexOf(index);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, index);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (index) => selected.indexOf(index) !== -1;

	const handleAcceptClick = (id) => {
		setAcceptingWorkId(id);
		setOpenAcceptDialog(true);
	};

	const handleAcceptConfirm = async () => {
		if (fetchUrlSegment === "demand-work-list") {
			if (!workName.trim()) {
				setWorkNameError("Work name is required");
				return;
			}
		}
		if (acceptingWorkId) {
			await handleAccept(acceptingWorkId);
			setOpenAcceptDialog(false);
			setAcceptingWorkId(null);
		}
	};

	const handleAccept = async (id) => {
		try {
			let response;
			if (fetchUrlSegment === "gramsabha-proceeding-list") {
				response = await gramSabhaFileStatus({
					ap_pdf_id: id,
					status_type: "ACCEPT",
				});
			} else {
				response = await updateWorkStatus({
					work_demand_id: id,
					status_type: "ACCEPT",
					url: "update-work-status",
					work_name: fetchUrlSegment === "demand-work-list" ? workName : "",
				});
			}
			setSnackbar({
				open: true,
				message: response.message || "Accepted successfully",
				severity: "success",
			});
			// Trigger a refresh of the data
			setRefreshTrigger((prev) => prev + 1);
		} catch (error) {
			console.error("Error accepting:", error);
			setSnackbar({
				open: true,
				message: "Failed to accept",
				severity: "error",
			});
		}
	};

	const handleReject = async (id) => {
		try {
			const reasonList = await fetchWorkReasonList(fetchUrlSegment);
			setRejectReasons([
				...reasonList,
				{ reject_reason_id: "others", reason_name: "Others" },
			]);
			setRejectingWorkId(id);
			setOpenRejectDialog(true);
		} catch (error) {
			console.error("Error fetching reject reasons:", error);
			setSnackbar({
				open: true,
				message: "Failed to prepare rejection",
				severity: "error",
			});
		}
	};

	const handleRejectConfirm = async () => {
		if (!selectedReasonId) return;

		try {
			let response;
			if (fetchUrlSegment === "gramsabha-proceeding-list") {
				response = await gramSabhaFileStatus({
					ap_pdf_id: rejectingWorkId,
					status_type: "REJECT",
					reject_reason_id:
						selectedReasonId === "others" ? null : selectedReasonId,
					reason_info: selectedReasonId === "others" ? otherRejectReason : null,
				});
			} else {
				response = await updateWorkStatus({
					work_demand_id: rejectingWorkId,
					status_type: "REJECT",
					reject_reason_id:
						selectedReasonId === "others" ? null : selectedReasonId,
					reason_info: selectedReasonId === "others" ? otherRejectReason : null,
					url:
						fetchUrlSegment === "shelf-of-works"
							? "update-apwork-status"
							: fetchUrlSegment === "pre-measurement-list"
							? "reject-work-status"
							: "update-work-status",
				});
			}

			setOpenRejectDialog(false);
			setSelectedReasonId("");
			setOtherRejectReason("");
			setRejectingWorkId(null);
			setSnackbar({
				open: true,
				message: response.message || "Rejected successfully",
				severity: "success",
			});
			setRefreshTrigger((prev) => prev + 1);
		} catch (error) {
			console.error("Error rejecting:", error);
			setSnackbar({
				open: true,
				message: "Failed to reject",
				severity: "error",
			});
		}
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbar({ ...snackbar, open: false });
	};

	const handleStart = async (workDemandId) => {
		try {
			const response = await startWorkPremeasurement(workDemandId);
			if (response.status) {
				setPreMeasurementData(response);
				setShowPreMeasurement(true);
				setSnackbar({
					open: true,
					message: "Pre-measurement started successfully",
					severity: "success",
				});
			} else {
				throw new Error("Failed to start work premeasurement");
			}
		} catch (error) {
			console.error("Error starting work premeasurement:", error);
			setSnackbar({
				open: true,
				message: "Failed to start pre-measurement",
				severity: "error",
			});
		}
	};

	const handleUploadComplete = (success, message) => {
		setSnackbar({
			open: true,
			message: message,
			severity: success ? "success" : "error",
		});
	};

	const handleActionPlanDelete = (apPdfId) => {
		// This function will be called after successful deletion
		// Refresh the data
		setRefreshTrigger((prev) => prev + 1);
	};

	const getPageTitle = () => {
		if (data?.key) {
			return data.key.replace(/([a-z])([A-Z])/g, "$1 $2");
		}
		// Fallback titles based on fetchUrlSegment
		const titles = {
			"raise-new-demand": t.raise_new_demand,
			"actionplan-proceeding": t.actionplan_proceeding,
			"actionplan-priority": t.actionplan_priority,
			// Add more mappings as needed
		};
		return titles[fetchUrlSegment] || "";
	};

	const handleViewHistory = (workDemandId) => {
		navigate(`${location.pathname}/${workDemandId}`);
	};

	// Function to filter data based on search input
	const getFilteredData = () => {
		if (!data || !data.results) return [];
		if (search.length < 2) return data.results; // Return all data if search input is less than 2 characters
		return data.results.filter((row) =>
			Object.values(row).some(
				(value) =>
					value !== null &&
					value.toString().toLowerCase().includes(search.toLowerCase())
			)
		);
	};

	const handleRevertWork = async (workDemandId) => {
		try {
			const inputData = JSON.stringify({
				work_demand_id: workDemandId,
				status_type: "REVERT",
			});

			const params = new URLSearchParams();
			params.append("input_data", inputData);

			const response = await fetch(`${DOMAIN_URL}/revert-work-status`, {
				method: "POST",
				headers: getAuthHeaders(),
				body: params,
			});

			const data = await response.json();

			if (data.status) {
				setSnackbar({
					open: true,
					message: data.message || "Work reverted successfully",
					severity: "success",
				});
				// Trigger a refresh of the data
				setRefreshTrigger((prev) => prev + 1);
			} else {
				throw new Error(data.message || "Failed to revert work");
			}
		} catch (error) {
			console.error("Error reverting work:", error);
			setSnackbar({
				open: true,
				message: "Failed to revert work",
				severity: "error",
			});
		}
	};

	if (fetchUrlSegment === "raise-new-demand") {
		return <RaiseDemandWork />;
	}

	return (
		<>
			{showPreMeasurement ? (
				<PreMeasurementForm
					data={preMeasurementData}
					onBack={() => {
						setShowPreMeasurement(false);
						// Optionally, you can refresh the data here
						setRefreshTrigger((prev) => prev + 1);
					}}
					onReject={handleReject}
				/>
			) : (
				<Paper sx={{ width: "100%", mb: 2 }}>
					<div className="flex flex-col sm:flex-row items-baseline justify-between px-4 pb-4 pt-[6px] sm:p-4">
						<Typography
							variant="h6"
							component="div"
							className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-0 xs:pt-2 pt-1"
						>
							{getPageTitle()}
						</Typography>
						<div className="flex flex-col sm:flex-row items-center">
							{fetchUrlSegment === "actionplan-proceeding" && (
								<div className="mb-4 sm:mb-0 sm:mr-4">
									<FileUploadButton
										onUploadComplete={handleUploadComplete}
										label="Upload Action Plan"
									/>
								</div>
							)}
							{data?.results && data.results.length > 0 && (
								<TextField
									variant="outlined"
									placeholder="Search…"
									value={search}
									onChange={handleSearch}
									className="w-full sm:w-64"
								/>
							)}
						</div>
					</div>

					{isLoading ? (
						<div className="p-4">Loading...</div>
					) : !data || !data.results || data.results.length === 0 ? (
						<div className="p-4">No data available</div>
					) : fetchUrlSegment === "actionplan-proceeding" ? (
						<ActionPlanProceeding
							data={data}
							onDelete={handleActionPlanDelete}
							onUploadComplete={handleUploadComplete}
							showActions={(row) => row.status !== "APPROVED"}
						/>
					) : isActionPlanPriority ? (
						<ActionPlanPriority
							data={data}
							onPriorityUpdate={handlePriorityUpdate}
						/>
					) : (
						<StyledTableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{fetchUrlSegment !== "actionplan-work-list" && (
											<TableCell
												key="actions"
												className="bg-slate-100 text-dark-600 font-semibold align-top"
											>
												Actions
											</TableCell>
										)}
										{data.results[0] &&
											Object.keys(data.results[0])
												.filter(
													(key) =>
														!(isPreMeasurementList && key === "status_name") &&
														!key.includes("_id")
												)
												.map((key) => (
													<TableCell
														key={key}
														sortDirection={orderBy === key ? order : false}
														className="bg-slate-100 text-dark-600 font-semibold align-top"
													>
														<TableSortLabel
															active={orderBy === key}
															direction={orderBy === key ? order : "asc"}
															onClick={() => handleRequestSort(key)}
														>
															{key.replaceAll("_", " ").toUpperCase()}
														</TableSortLabel>
													</TableCell>
												))}
									</TableRow>
								</TableHead>
								<TableBody>
									{getFilteredData()
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											const isItemSelected = isSelected(index);
											const labelId = `enhanced-table-checkbox-${index}`;
											const isReverify =
												isPreMeasurementList && row.reverify_status_id === "1";

											return (
												<TableRow
													hover
													role="checkbox"
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={`${data.key}-${index}`}
													selected={isItemSelected}
													sx={isReverify ? { backgroundColor: "#fff9c4" } : {}}
												>
													{fetchUrlSegment !== "actionplan-work-list" && (
														<TableCell key={`actions-${index}`}>
															{fetchUrlSegment === "approved-demand-list" ? (
																<div className="flex space-x-2">
																	<Tooltip title="History">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				handleViewHistory(row.work_demand_id)
																			}
																		>
																			<HistoryIcon />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="View">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				navigate(
																					`/core/premeasurement-data/demand/${row.work_demand_id}?readOnly=1`
																				)
																			}
																		>
																			<InfoIcon />
																		</IconButton>
																	</Tooltip>
																</div>
															) : fetchUrlSegment === "list-of-works" ? (
																<div className="flex space-x-2">
																	<Tooltip title="History">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				handleViewHistory(row.work_demand_id)
																			}
																		>
																			<HistoryIcon />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="View">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				navigate(
																					`/core/premeasurement-data/demand/${row.work_demand_id}?readOnly=1`
																				)
																			}
																		>
																			<InfoIcon />
																		</IconButton>
																	</Tooltip>
																</div>
															) : fetchUrlSegment === "shelf-of-works" ||
															  fetchUrlSegment === "forwarded-works-tpzp" ? (
																<div className="flex space-x-2">
																	<Tooltip title="History">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				handleViewHistory(row.work_demand_id)
																			}
																		>
																			<HistoryIcon />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="View">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				navigate(
																					`/core/premeasurement-data/demand/${row.work_demand_id}?readOnly=1`
																				)
																			}
																		>
																			<InfoIcon />
																		</IconButton>
																	</Tooltip>
																	<>
																		{/* {fetchUrlSegment === "shelf-of-works" &&
																			row.status_id === "9" && (
																				<Tooltip title="Reject">
																					<IconButton
																						color="error"
																						onClick={(e) => {
																							e.stopPropagation();
																							handleReject(row.work_demand_id);
																						}}
																					>
																						<ThumbDownIcon />
																					</IconButton>
																				</Tooltip>
																			)} */}
																	</>
																</div>
															) : fetchUrlSegment === "rejected-demand-list" ||
															  fetchUrlSegment === "rejected-works-tpzp" ? (
																<div className="flex space-x-2">
																	<Tooltip title="History">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				handleViewHistory(row.work_demand_id)
																			}
																		>
																			<HistoryIcon />
																		</IconButton>
																	</Tooltip>
																	{row?.gp_dd_flag_id !== "1" ? (
																		<>
																			{fetchUrlSegment !==
																			"rejected-demand-list" ? (
																				<Tooltip title="Partial Submit">
																					<IconButton
																						color="success"
																						onClick={() =>
																							navigate(
																								`/core/premeasurement-partial-submit/${row.work_demand_id}?partialSubmit=0`
																							)
																						}
																					>
																						<RunningWithErrorsIcon />
																					</IconButton>
																				</Tooltip>
																			) : (
																				<Tooltip title="Revert Work">
																					<IconButton
																						color="warning"
																						onClick={() =>
																							handleRevertWork(
																								row.work_demand_id
																							)
																						}
																					>
																						<SettingsBackupRestore />
																					</IconButton>
																				</Tooltip>
																			)}
																		</>
																	) : null}
																</div>
															) : fetchUrlSegment ===
																	"gramsabha-proceeding-list" ||
															  fetchUrlSegment === "demand-work-list" ? (
																<div className="flex space-x-2">
																	<Button
																		variant="contained"
																		color="success"
																		onClick={(e) => {
																			e.stopPropagation();
																			handleAcceptClick(
																				fetchUrlSegment ===
																					"gramsabha-proceeding-list"
																					? row.ap_pdf_id
																					: row.work_demand_id
																			);
																		}}
																		className="w-1/2"
																	>
																		Accept
																	</Button>
																	<Button
																		variant="contained"
																		color="error"
																		onClick={(e) => {
																			e.stopPropagation();
																			handleReject(
																				fetchUrlSegment ===
																					"gramsabha-proceeding-list"
																					? row.ap_pdf_id
																					: row.work_demand_id
																			);
																		}}
																		className="w-1/2"
																	>
																		Reject
																	</Button>
																</div>
															) : fetchUrlSegment === "work-list" ? (
																<Button
																	variant="outlined"
																	color="primary"
																	size="small"
																	onClick={() =>
																		window.open(
																			`/core/premeasurement-data/${row.work_id}`,
																			"_blank"
																		)
																	}
																>
																	View
																</Button>
															) : isPreMeasurementList ? (
																<div className="flex space-x-2">
																	<Tooltip
																		title={isReverify ? "Re-verify" : "Start"}
																	>
																		<IconButton
																			color="success"
																			onClick={() =>
																				handleStart(row.work_demand_id)
																			}
																		>
																			<ThumbUpIcon />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Reject">
																		<IconButton
																			color="error"
																			onClick={(e) => {
																				e.stopPropagation();
																				handleReject(row.work_demand_id);
																			}}
																		>
																			<ThumbDownIcon />
																		</IconButton>
																	</Tooltip>
																	{/* <Tooltip title="Partial Submit">
																		<IconButton
																			color="primary"
																			onClick={() =>
																				navigate(
																					`/core/premeasurement-partial-submit/${row.work_demand_id}?partialSubmit=1`
																				)
																			}
																		>
																			<RunningWithErrorsIcon />
																		</IconButton>
																	</Tooltip> */}
																</div>
															) : isPartialMeasurementList ? (
																<div className="flex space-x-2">
																	<Tooltip title="Start">
																		<IconButton
																			color="success"
																			onClick={() =>
																				navigate(
																					`/core/premeasurement-partial-submit/${row.work_demand_id}?partialSubmit=0`
																				)
																			}
																		>
																			<ThumbUpIcon />
																		</IconButton>
																	</Tooltip>

																	<Tooltip title="Reject">
																		<IconButton
																			color="error"
																			onClick={(e) => {
																				e.stopPropagation();
																				handleReject(row.work_demand_id);
																			}}
																		>
																			<ThumbDownIcon />
																		</IconButton>
																	</Tooltip>
																</div>
															) : (
																<IconButton
																	onClick={() =>
																		editMode[index]
																			? handleSave(index)
																			: handleEdit(index)
																	}
																	className="btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
																>
																	{editMode[index] ? (
																		<SaveIcon />
																	) : (
																		<EditIcon />
																	)}
																</IconButton>
															)}
														</TableCell>
													)}
													{Object.keys(row)
														.filter(
															(key) =>
																!(
																	isPreMeasurementList && key === "status_name"
																) && !key.includes("_id")
														)
														.map((key) => (
															<TableCell key={`${index}-${key}`}>
																{key === "work_image" ? (
																	<img
																		src={`${imgPath}${row[key]}`}
																		alt={row.work_title}
																		style={{ width: "100px", height: "auto" }}
																		loading="lazy"
																	/>
																) : key === "status_name" ? (
																	<Switch
																		checked={row.status_name === "ACTIVE"}
																		onChange={(e) =>
																			handleSwitchChange(
																				index,
																				e.target.checked
																			)
																		}
																		color="primary"
																	/>
																) : editMode[index] ? (
																	<TextField
																		value={row[key] || ""}
																		onChange={(e) =>
																			handleInputChange(
																				index,
																				key,
																				e.target.value
																			)
																		}
																	/>
																) : (
																	row[key]
																)}
															</TableCell>
														))}
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</StyledTableContainer>
					)}
					{!data?.results ||
					data.results.length === 0 ||
					fetchUrlSegment === "actionplan-proceeding" ? null : (
						<TablePagination
							rowsPerPageOptions={[20, 50, 100]}
							component="div"
							count={data.results.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
					<Dialog
						open={openRejectDialog}
						onClose={() => setOpenRejectDialog(false)}
						classes={{
							paper: "min-w-full md:min-w-[400px]",
						}}
					>
						<DialogTitle>Reject Work</DialogTitle>
						<DialogContent>
							<FormControl fullWidth margin="normal">
								<InputLabel id="reject-reason-label">Reject Reason</InputLabel>
								<Select
									labelId="reject-reason-label"
									value={selectedReasonId}
									onChange={(e) => setSelectedReasonId(e.target.value)}
									label="Reject Reason"
								>
									{rejectReasons.map((reason) => (
										<MenuItem
											key={reason.reject_reason_id}
											value={reason.reject_reason_id}
										>
											{reason.reason_name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{selectedReasonId === "others" && (
								<TextField
									fullWidth
									margin="normal"
									label="Other Reason"
									variant="outlined"
									value={otherRejectReason}
									onChange={(e) => setOtherRejectReason(e.target.value)}
									multiline
									rows={3}
								/>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpenRejectDialog(false)}>Cancel</Button>
							<Button
								onClick={handleRejectConfirm}
								color="primary"
								disabled={
									selectedReasonId === "others" && !otherRejectReason.trim()
								}
							>
								Confirm Reject
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={openAcceptDialog}
						onClose={() => setOpenAcceptDialog(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							{"Confirm Accept"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{fetchUrlSegment === "demand-work-list" ? (
									<>
										<TextField
											autoFocus
											margin="dense"
											label="Work Name"
											type="text"
											fullWidth
											variant="outlined"
											value={workName}
											onChange={(e) => {
												setWorkName(e.target.value);
												if (e.target.value.trim()) setWorkNameError("");
											}}
											error={!!workNameError}
											helperText={workNameError}
											required
										/>
									</>
								) : (
									<p>Are you sure you want to accept this work demand?</p>
								)}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpenAcceptDialog(false)}>Cancel</Button>
							<Button onClick={handleAcceptConfirm} color="primary" autoFocus>
								Confirm
							</Button>
						</DialogActions>
					</Dialog>
					<Snackbar
						open={snackbar.open}
						autoHideDuration={3000}
						onClose={() => setSnackbar({ ...snackbar, open: false })}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<Alert
							onClose={() => setSnackbar({ ...snackbar, open: false })}
							severity={snackbar.severity}
						>
							{snackbar.message}
						</Alert>
					</Snackbar>
				</Paper>
			)}
		</>
	);
};

export default ContentArea;
