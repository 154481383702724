import { Box, CssBaseline, IconButton, Drawer } from "@mui/material";
import Footer from "component/Footer";
import Sidebar from "./common/Sidebar";
import Header from "component/Header/CommonHeader";
import ContentArea from "./common/ContentArea";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import GpBudget from "./GpBudgetAndCommencement/GpBudget";
import GpCommencement from "./GpBudgetAndCommencement/GpCommencement";
import MenuIcon from "@mui/icons-material/Menu"; // Import Menu icon
import ActionPlanDraftList from "./common/ActionPlanDraftList/ActionPlanDraftList";
import DemandHistory from "./DemandHistory/DemandHistory";
import PremeasurementInfo from "./PremeasurementInfo";
import ActionPlanPriority from "./common/ActionPlanPriority";
import CommencementOutdatedAlert from "component/CommencementOutdatedAlert";
import ActionPlanPrepareList from "./ActionPlanPrepareList";
import VerificationWorkList from "./common/VerificationWorkList";
import StartVerificationWorkList from "./common/VerificationWorkList/StartVerificationWorkList";
import ForwardedWorksList from "./common/VerificationWorkList/ForwardedWorksList";
import ApprovedWorkList from "./ApprovedWorkList";

const verificationWorkRoutes = [
	"tpad",
	"tpeo",
	"zpdd",
	"zpds",
	"zpceo",
	"state",
].map((prefix) => (
	<Route
		key={prefix}
		path={`/${prefix}-workgp-list`}
		element={<VerificationWorkList />}
	/>
));

const workGpListRoutes = ["tpad", "tpeo", "zpdd", "zpds", "zpceo", "state"].map(
	(prefix) => (
		<Route
			key={prefix}
			path={`/${prefix}-workgp-list/:gp_id`}
			element={<StartVerificationWorkList />}
		/>
	)
);

const allPrefixes = ["tpad", "tpeo", "zpdd", "zpds", "zpceo"];
const gpOnlyPrefixes = ["tpad", "tpeo", "zpds", "zpceo"];

const forwardedListsRoutes = allPrefixes.map((prefix) => (
	<Route
		key={`${prefix}-tp`}
		path={`/${prefix}-forwarded-lists/:tp_id`}
		element={<ForwardedWorksList />}
	/>
));

const forwardedListGpRoutes = gpOnlyPrefixes.map((prefix) => (
	<Route
		key={`${prefix}-gp`}
		path={`/${prefix}-forwarded-list/:gp_id`}
		element={<ForwardedWorksList />}
	/>
));

const rejectedListsRoutes = allPrefixes.map((prefix) => (
	<Route
		key={`${prefix}-tp-rejected`}
		path={`/${prefix}-rejected-lists/:tp_id`}
		element={<ForwardedWorksList />}
	/>
));

const rejectedListGpRoutes = gpOnlyPrefixes.map((prefix) => (
	<Route
		key={`${prefix}-gp-rejected`}
		path={`/${prefix}-rejected-list/:gp_id`}
		element={<ForwardedWorksList />}
	/>
));

// Special routes for ZPCEO
const zpceoSpecialRoutes = [
	<Route
		key="zpceo-approved"
		path="/zpceo-approved-lists/:gp_id"
		element={<ForwardedWorksList />}
	/>,
];

const Index = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer open/close

	useEffect(() => {
		if (location.pathname === "/core") {
			navigate("/core/dashboard");
		}
	}, [location, navigate]);

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen); // Toggle drawer state
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
			<CssBaseline />
			<Header />

			<CommencementOutdatedAlert />
			<Box sx={{ display: "flex", flexGrow: 1, position: "relative" }}>
				{/* Button to open the drawer on mobile */}
				<IconButton
					sx={{
						display: { xs: "block", md: "none" },
						position: "absolute",
						left: 16,
						top: 16, // Adjust this value as needed
						zIndex: 1000,
					}}
					onClick={toggleDrawer}
				>
					<MenuIcon />
				</IconButton>

				{/* Drawer for the sidebar on mobile */}
				<Drawer
					anchor="left"
					open={drawerOpen}
					onClose={toggleDrawer}
					sx={{
						"& .MuiDrawer-paper": {
							width: 240, // Width of the sidebar
						},
					}}
				>
					<Sidebar onLinkClick={toggleDrawer} />{" "}
					{/* Pass toggleDrawer as a prop */}
				</Drawer>

				{/* Sidebar for larger screens */}
				<Box
					sx={{ display: { xs: "none", md: "block" } }} // Hide on mobile, show on md and larger
				>
					<Sidebar />
				</Box>

				<Box
					component="main"
					sx={{
						flexGrow: 1,
						p: { xs: 2, sm: 3 },
						overflowX: "auto",
						width: "100%",
					}}
				>
					<Routes>
						<Route path="/dashboard" element={<DefaultDashboardContent />} />
						<Route path="/*" element={<ContentArea />} />
						<Route path="/gp-budget" element={<GpBudget />} />
						<Route path="/gp-commencement" element={<GpCommencement />} />
						<Route
							path="/actionplan-draft-list"
							element={<ActionPlanDraftList />}
						/>
						<Route
							path="/actionplan-priority"
							element={<ActionPlanPriority />}
						/>
						<Route path="/approved-work-list" element={<ApprovedWorkList />} />
						<Route
							path="/approved-demand-list/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/list-of-works/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/shelf-of-works/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/forwarded-works-tpzp/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/rejected-demand-list/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/rejected-works-tpzp/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/premeasurement-data/:work_id"
							element={<PremeasurementInfo />}
						/>
						<Route
							path="/premeasurement-data/demand/:work_demand_id"
							element={<PremeasurementInfo />}
						/>
						<Route
							path="/premeasurement-partial-submit/:work_demand_id"
							element={<PremeasurementInfo />}
						/>

						<Route
							path="/actionplan-prepare-list"
							element={<ActionPlanPrepareList />}
						/>
						{verificationWorkRoutes}
						{workGpListRoutes}
						{forwardedListsRoutes}
						{forwardedListGpRoutes}
						{rejectedListsRoutes}
						{rejectedListGpRoutes}
						{zpceoSpecialRoutes}

						<Route
							path="/tpad-forwarded-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/tpeo-forwarded-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpdd-forwarded-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpds-forwarded-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpceo-forwarded-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>

						<Route
							path="/tpad-forwarded-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/tpeo-forwarded-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpds-forwarded-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpceo-forwarded-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpceo-approved-lists/:gp_id"
							element={<ForwardedWorksList />}
						/>

						<Route
							path="/tpad-rejected-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/tpeo-rejected-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpdd-rejected-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpds-rejected-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpceo-rejected-lists/:tp_id"
							element={<ForwardedWorksList />}
						/>

						<Route
							path="/tpad-rejected-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/tpeo-rejected-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpds-rejected-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
						<Route
							path="/zpceo-rejected-list/:gp_id"
							element={<ForwardedWorksList />}
						/>
					</Routes>
				</Box>
			</Box>

			<Box component="footer" sx={{ p: 0, bgcolor: "background.paper" }}>
				<Footer />
			</Box>
		</Box>
	);
};

export default Index;

export const DefaultDashboardContent = () => {
	return (
		<div style={{ marginTop: "50px", marginLeft: "40px" }}>Your dashboard</div>
	);
};
