import React, { useState, useEffect } from "react";
import {
	Button,
	Modal,
	Typography,
	Box,
	Snackbar,
	Alert,
	CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";
import { generateOTP } from "./api";
import OTPVerification from "pages/auth/OTPVerification";
import { Warning } from "@mui/icons-material";

interface SignDSCFlowProps {
	adminInfo: {
		first_name: string;
		last_name: string;
		designation_name: string;
		phone_number: string;
		role_id: string;
	};
	onSuccess: () => void;
	buttonText?: string;
	autoOpen?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	confirmationMessage?: string;
}

const SignDSCFlow: React.FC<SignDSCFlowProps> = ({
	adminInfo,
	onSuccess,
	buttonText = "Sign DSC",
	autoOpen = false,
	disabled = false,
	isLoading = false,
	confirmationMessage,
}) => {
	const [open, setOpen] = useState(autoOpen);
	const [otp, setOtp] = useState("");
	const [otpVerified, setOtpVerified] = useState(false);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success" as "success" | "error",
	});
	const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
	const [showOtpInput, setShowOtpInput] = useState(false);

	const cookieName = `otpDSC_${adminInfo.phone_number}_2`;
	const hasVerifiedOTP = Cookies.get(cookieName);

	useEffect(() => {
		if (hasVerifiedOTP) {
			setOtpVerified(true);
		}
	}, [hasVerifiedOTP]);

	useEffect(() => {
		if (autoOpen) {
			setOpen(true);
		}
	}, [autoOpen]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setOtp("");
		setOtpVerified(false);
		setIsConfirmDisabled(false);
		setShowOtpInput(false);
	};

	const handleConfirmDetails = async () => {
		if (!otpVerified) {
			setIsConfirmDisabled(true);
			try {
				const response = await generateOTP({
					user_name: adminInfo.phone_number,
					new_user: true,
				});
				if (response.status) {
					setOtp(response.otp);
					setShowOtpInput(true);
				}
			} catch (error) {
				console.error("Error generating OTP:", error);
				setSnackbar({
					open: true,
					message: "Failed to generate OTP",
					severity: "error",
				});
			} finally {
				setIsConfirmDisabled(false);
			}
		} else {
			handleOtpSuccess();
		}
	};

	const handleOtpSuccess = async () => {
		Cookies.set(cookieName, "verified", { expires: 1, secure: true });
		onSuccess();
		handleClose();
	};

	return (
		<>
			{!autoOpen && (
				<Button
					variant="contained"
					color="info"
					onClick={handleOpen}
					disabled={disabled || isLoading}
					startIcon={
						isLoading ? <CircularProgress size={20} color="inherit" /> : null
					}
				>
					{isLoading ? "Processing..." : buttonText}
				</Button>
			)}
			<Modal
				open={open}
				onClose={handleClose}
				className="flex items-center justify-center p-4"
			>
				<Box className="bg-white rounded-lg shadow-xl w-full max-w-md mx-auto">
					{/* Header */}
					<div className="p-6 border-b">
						<Typography variant="h6" className="text-xl font-semibold">
							Confirm Details
						</Typography>
					</div>

					{/* Content */}
					<div className="p-6 space-y-4">
						{confirmationMessage && (
							<Box
								sx={{
									backgroundColor: "#fff3e0",
									border: "1px solid #ffb74d",
									borderRadius: "4px",
									padding: "12px",
									marginBottom: "16px",
									display: "flex",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<Warning color="warning" />
								<Typography>{confirmationMessage}</Typography>
							</Box>
						)}

						<div className="space-y-2">
							<Typography className="text-sm text-gray-600">
								First Name:
								<span className="ml-2 font-medium text-gray-900">
									{adminInfo.first_name}
								</span>
							</Typography>
							<Typography className="text-sm text-gray-600">
								Last Name:
								<span className="ml-2 font-medium text-gray-900">
									{adminInfo.last_name}
								</span>
							</Typography>
							<Typography className="text-sm text-gray-600">
								Designation:
								<span className="ml-2 font-medium text-gray-900">
									{adminInfo.designation_name}
								</span>
							</Typography>
						</div>

						{/* Actions */}
						<div className="pt-4 text-right">
							<Button
								variant="contained"
								color="primary"
								onClick={handleConfirmDetails}
								disabled={isConfirmDisabled || showOtpInput}
								className="w-full sm:w-auto"
							>
								Confirm
							</Button>
						</div>

						{/* OTP Verification */}
						{showOtpInput && (
							<div className="mt-6">
								<OTPVerification
									phoneNumber={adminInfo.phone_number}
									onFirstTimeOTPSuccess={handleOtpSuccess}
									type="FIRST_TIME"
								/>
							</div>
						)}
					</div>
				</Box>
			</Modal>

			{/* Snackbar */}
			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={() => setSnackbar({ ...snackbar, open: false })}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				className="mt-20"
			>
				<Alert
					severity={snackbar.severity}
					onClose={() => setSnackbar({ ...snackbar, open: false })}
					className="w-full sm:max-w-md shadow-lg"
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default SignDSCFlow;
