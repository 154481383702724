import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import MainLogo from "assets/images/logo_nrega.png";
import UserContext from "contexts/UserContext";
import SignInSignUpLink from "./SignInSignUpLink";
import LanguageSwitcher from "component/LanguageSwitcher";
import { translations, getCurrentLanguage } from "../../utils/translations";
import { isMobile } from "react-device-detect";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [isOpen, setMenu] = useState(true);
	const { userState } = useContext(UserContext);
	const [isScrolled, setIsScrolled] = useState(false);

	const toggleMenu = () => {
		setMenu(!isOpen);
	};

	const handleScroll = () => {
		if (window.scrollY > 50) {
			setIsScrolled(true);
		} else {
			setIsScrolled(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<nav
			className={`navbar ${
				isScrolled || isMobile ? "header-light" : "header-dark"
			}`}
			id="navbar"
		>
			<div className="px-4 flex flex-wrap lg_992:flex-row items-center justify-between  w-full">
				<div className="flex items-center justify-between w-full lg_992:w-auto">
					<div className="flex items-center">
						<Link to="/">
							<img src={MainLogo} className="h-16" height={65} alt="Logo" />
						</Link>
						<p
							className={`font-bold text-2xl ml-5 nav-link ${
								isScrolled || isMobile ? "text-dark" : "text-white"
							}`}
						>
							Mahatma Gandhi NREGA
						</p>
					</div>
					<button
						type="button"
						className="collapse-btn inline-flex items-center ms-auto text-dark dark:text-white lg_992:hidden"
						onClick={toggleMenu}
					>
						<span className="sr-only">Navigation Menu</span>
						<MenuIcon />
					</button>
				</div>
				<div className="navigation flex items-center lg_992:order-1 ms-auto">
					<div
						className={`${
							isOpen === true ? "hidden" : "block"
						} navigation lg_992:flex`}
						id="menu-collapse"
					>
						<ul
							className="navbar-nav nav-light flex flex-col lg:flex-row items-center"
							id="navbar-navlist"
						>
							{" "}
							{/* Updated to flex-col for xs */}
							<li>
								<ScrollLink
									className="nav-item"
									to="home"
									activeclassname="active"
									spy={true}
									smooth={false}
									duration={200}
								>
									<span className="nav-link">{t.home}</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink
									className="nav-item"
									activeclassname="active"
									spy={true}
									smooth={false}
									duration={200}
									to="about"
								>
									<span className="nav-link">{t.about_us}</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink
									className="nav-item"
									to="features"
									activeclassname="active"
									spy={true}
									smooth={false}
									duration={200}
								>
									<span className="nav-link">{t.services}</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink
									className="nav-item"
									to="portfolio"
									activeclassname="active"
									spy={true}
									smooth={false}
									duration={200}
								>
									<span className="nav-link">{t.portfolio}</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink
									className="nav-item"
									to="contact"
									activeclassname="active"
									spy={true}
									smooth={false}
									duration={200}
								>
									<span className="nav-link">{t.contact_us}</span>
								</ScrollLink>
							</li>
							<li>
								<a
									href="https://rdnrega.in/admin/public_dashboard/index.php"
									target="_blank"
									rel="noopener noreferrer"
									className="nav-item"
								>
									<span className="nav-link">Public Dashboard</span>
								</a>
							</li>
							{!isMobile && (
								<li className="nav-item">
									<span className="nav-link text-gray-400">|</span>
								</li>
							)}
							<li>
								<LanguageSwitcher />
							</li>
							{!isMobile && (
								<li className="nav-item">
									<span className="nav-link text-gray-400">|</span>
								</li>
							)}
							<li className="nav-item">
								<SignInSignUpLink />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Header;
