import { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	TableSortLabel,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Tooltip,
	IconButton,
	MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import {
	Warning as WarningIcon,
	RunningWithErrors as RunningWithErrorsIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { viewHtmlReport } from "utils/reports";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const WorksTable = ({ data, onDataUpdate }) => {
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [search, setSearch] = useState("");
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedWorkId, setSelectedWorkId] = useState(null);
	const [workCode, setWorkCode] = useState("");
	const [nregaWorkId, setNregaWorkId] = useState(null);
	const [submitMessage, setSubmitMessage] = useState("");
	const [submitError, setSubmitError] = useState(false);
	const [specialDrives, setSpecialDrives] = useState([]);
	const [selectedDrive, setSelectedDrive] = useState("");
	const [convergenceAmount, setConvergenceAmount] = useState("");
	const [convergenceStatus, setConvergenceStatus] = useState("NO");
	const navigate = useNavigate();

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Filter data based on search
	const filteredResults =
		data?.filter((row) => {
			return Object.values(row).some((value) =>
				String(value).toLowerCase().includes(search.toLowerCase())
			);
		}) || [];

	// Sort data
	const sortedData = filteredResults.sort((a, b) => {
		if (!orderBy) return 0;

		if (order === "desc") {
			return String(b[orderBy]).localeCompare(String(a[orderBy]));
		} else {
			return String(a[orderBy]).localeCompare(String(b[orderBy]));
		}
	});

	const handlePickWork = async (
		workDemandId,
		workCode = "",
		nregaWorkId = null,
		driveTagId = "",
		convergenceAmt = ""
	) => {
		setSelectedWorkId(workDemandId);
		setWorkCode(workCode || "");
		setNregaWorkId(nregaWorkId);
		setSelectedDrive(driveTagId || "");
		setConvergenceAmount(convergenceAmt || "");
		setSubmitMessage("");

		try {
			const response = await fetch(
				`${DOMAIN_URL}/special-drives/${workDemandId}`,
				{
					headers: getAuthHeaders(),
				}
			);
			const data = await response.json();
			setSpecialDrives(data.results || []);
			setConvergenceStatus(data.convergence_status);
		} catch (error) {
			console.error("Error fetching special drives:", error);
			setSpecialDrives([]);
		}

		setOpenDialog(true);
	};

	const handleSubmitWorkCode = async () => {
		try {
			// Validate required fields
			if (!workCode) {
				setSubmitMessage("Work code is required");
				setSubmitError(true);
				return;
			}

			const inputData = {
				work_demand_id: selectedWorkId,
				nrega_work_code: workCode,
				nrega_work_id: nregaWorkId || "", // Ensure it's not null
			};

			if (selectedDrive) {
				inputData.drive_tag_id = selectedDrive;
			}
			if (convergenceStatus === "YES" && convergenceAmount) {
				inputData.convergence_amount = convergenceAmount;
			}

			const response = await fetch(`${DOMAIN_URL}/update-nrega-work-code`, {
				method: "POST",
				headers: getAuthHeaders(),
				body: new URLSearchParams({
					input_data: JSON.stringify(inputData),
				}),
			});

			const data = await response.json();
			setSubmitMessage(data.message);
			setSubmitError(!data.status);

			if (data.status) {
				setTimeout(() => {
					setOpenDialog(false);
					if (onDataUpdate) onDataUpdate(); // Callback to parent to refresh data
				}, 1500);
			}
		} catch (error) {
			console.error("Error updating work code:", error);
			setSubmitMessage("Failed to update work code");
			setSubmitError(true);
		}
	};

	const handleViewWorkInfo = (workDemandId) => {
		viewHtmlReport("work-info", workDemandId);
	};

	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<div className="flex justify-end p-4">
				<TextField
					variant="outlined"
					placeholder="Search..."
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					className="w-full sm:w-64"
					size="small"
				/>
			</div>
			<StyledTableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell className="bg-slate-100 text-dark-600 font-semibold align-top">
								Action
							</TableCell>
							{data[0] &&
								Object.keys(data[0])
									.filter((key) => !key.includes("_id"))
									.map((key) => (
										<TableCell
											key={key}
											sortDirection={orderBy === key ? order : false}
											className="bg-slate-100 text-dark-600 font-semibold align-top"
										>
											<TableSortLabel
												active={orderBy === key}
												direction={orderBy === key ? order : "asc"}
												onClick={() => handleRequestSort(key)}
											>
												{key.replaceAll("_", " ").toUpperCase()}
											</TableSortLabel>
										</TableCell>
									))}
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedData
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => (
								<TableRow
									hover
									key={row.work_code || index}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell>
										<div className="flex gap-2">
											{row.status_id === "1" ? (
												<Button
													variant="contained"
													size="small"
													onClick={() =>
														handlePickWork(
															row.work_demand_id,
															row.nrega_work_code,
															row.nrega_work_id,
															row.drive_tag_id,
															row.convergence_amount
														)
													}
												>
													Update work code
												</Button>
											) : row.status_id === "2" ? (
												row.gp_dd_flag_id !== "1" ? (
													<Tooltip title="Partial Submit">
														<IconButton
															color="warning"
															onClick={() =>
																navigate(
																	`/core/premeasurement-partial-submit/${row.work_demand_id}?partialSubmit=0`
																)
															}
														>
															<RunningWithErrorsIcon />
														</IconButton>
													</Tooltip>
												) : (
													<Typography
														color="warning.main"
														sx={{
															display: "flex",
															alignItems: "center",
															gap: 0.5,
														}}
													>
														<WarningIcon fontSize="small" />
														Pre-measurement incomplete
													</Typography>
												)
											) : (
												<Button
													variant="contained"
													color="primary"
													size="small"
													onClick={() => handlePickWork(row.work_demand_id)}
												>
													PICK Work
												</Button>
											)}
											<Button
												variant="contained"
												color="info"
												size="small"
												onClick={() => handleViewWorkInfo(row.work_demand_id)}
											>
												View
											</Button>
										</div>
									</TableCell>
									{Object.keys(row)
										.filter((key) => !key.includes("_id"))
										.map((key) => (
											<TableCell key={`${row.work_code || index}-${key}`}>
												{row[key]}
											</TableCell>
										))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</StyledTableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component="div"
				count={filteredResults.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<DialogTitle>Enter Work Code</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						required
						margin="dense"
						label="Work Code"
						fullWidth
						value={workCode}
						onChange={(e) => setWorkCode(e.target.value)}
						error={submitError}
						helperText={submitError ? "Work code is required" : ""}
					/>

					{specialDrives.length > 0 && (
						<TextField
							select
							margin="dense"
							label="Special Drive"
							fullWidth
							value={selectedDrive}
							onChange={(e) => setSelectedDrive(e.target.value)}
						>
							{specialDrives.map((drive) => (
								<MenuItem key={drive.drive_tag_id} value={drive.drive_tag_id}>
									{drive.drive_tag_name}
								</MenuItem>
							))}
						</TextField>
					)}

					{convergenceStatus === "YES" && (
						<TextField
							margin="dense"
							label="Convergence Amount"
							fullWidth
							type="number"
							inputProps={{
								pattern: "^\\d*\\.?\\d{0,3}$",
							}}
							value={convergenceAmount}
							onChange={(e) => {
								const value = e.target.value;
								// Only update if the value has 3 or fewer decimal places
								if (!value || !/\.\d{4,}$/.test(value)) {
									setConvergenceAmount(value);
								}
							}}
							helperText="Maximum 3 decimal places allowed"
						/>
					)}

					{submitMessage && (
						<Typography
							color={submitError ? "error" : "success.main"}
							variant="caption"
							dangerouslySetInnerHTML={{ __html: submitMessage }}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialog(false)}>Cancel</Button>
					<Button onClick={handleSubmitWorkCode} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

export default WorksTable;
