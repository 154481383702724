import { useCallback, useEffect, useState, lazy } from "react";
import Header from "component/Header/CommonHeader";
import Footer from "component/Footer";
import EnterOTPExisting from "pages/auth/EnterOTPExisting/index";
import {
	Button,
	TextField,
	Typography,
	Alert,
	CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";
import { generateOtp } from "./auth/api";
import { getCurrentLanguage, translations } from "utils/translations";
import { Link } from "react-router-dom";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";

const RaiseNewDemand = lazy(() => import("component/RaiseNewDemand"));

function PublicRaiseDemandWork() {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [showPhoneVerification, setShowPhoneVerification] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number
	const [isOtpSent, setIsOtpSent] = useState(false); // State to track OTP sent status
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [loading, setLoading] = useState(true);
	const [demandRights, setDemandRights] = useState(null);
	const [error, setError] = useState(null);

	const isAuthenticated = useCallback(() => {
		return !!Cookies.get("user_name");
	}, []);

	useEffect(() => {
		const checkDemandRights = async () => {
			try {
				const response = await fetch(`${DOMAIN_URL}/check-demand-rights`, {
					method: "GET",
					headers: getAuthHeaders(),
				});

				if (!response.ok) {
					throw new Error("Failed to fetch demand rights");
				}

				const data = await response.json();
				setDemandRights(data);
			} catch (err) {
				console.error("Error checking demand rights:", err);
				setError("Failed to load page. Please try again later.");
			} finally {
				setLoading(false);
			}
		};

		checkDemandRights();
	}, []);

	useEffect(() => {
		if (!loading) {
			setShowPhoneVerification(!isAuthenticated());
		}
	}, [isAuthenticated, loading]);

	const handlePhoneSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true); // Disable the button
		try {
			await handleVerifyPhoneNumber();
		} catch (error) {
			setIsSubmitting(false); // Re-enable the button if there's an error
		}
	};

	const handleVerifyPhoneNumber = async () => {
		const result = await generateOtp(phoneNumber, "true"); // Call generateOtp
		if (result && result.status) {
			setIsOtpSent(true);
			// setOtp(result.data.otp_value);
		} else {
			setIsSubmitting(false); // Re-enable the button if OTP sending fails
		}
	};

	const handleBackToRaiseNewDemandClick = () => {
		if (!isAuthenticated()) {
			setShowPhoneVerification(true);
			setPhoneNumber("");
			setIsOtpSent(false);
		}
	};

	const handleNewAccountDemand = () => {
		Cookies.remove("user_name");
		setShowPhoneVerification(true);
		setPhoneNumber("");
		setIsOtpSent(false);
		setIsSubmitting(false);
	};

	return (
		<div className="flex flex-col min-h-screen">
			<Header />
			<main className="flex-grow container mx-auto p-4">
				{loading ? (
					<div className="flex justify-center items-center h-64">
						<CircularProgress />
					</div>
				) : error ? (
					<Alert severity="error" className="mt-4">
						{error}
					</Alert>
				) : demandRights && demandRights.results.public_demand === "1" ? (
					showPhoneVerification ? (
						<div className="w-full flex items-center justify-center bg-gray-100 py-8">
							<div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
								<Typography
									variant="h5"
									component="div"
									className="font-bold mb-2"
								>
									{t.public_verify_phone_number}
								</Typography>
								<form
									onSubmit={handlePhoneSubmit}
									onKeyDown={(e) =>
										isSubmitting && e.key === "Enter" && e.preventDefault()
									}
									className="space-y-4"
								>
									<TextField
										label={t.phone_number}
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
										fullWidth
										margin="normal"
										required
										type="tel"
									/>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
										disabled={isSubmitting} // Disable the button if submitting
									>
										{t.send_otp}
									</Button>
								</form>
								{isOtpSent && (
									<div className="mt-6 space-y-4">
										<EnterOTPExisting
											phoneNumber={phoneNumber}
											onSuccess={(result) => {
												Cookies.set("user_name", result.user_name, {
													secure: true,
												});
												setShowPhoneVerification(false);
											}}
											newUserFlag="true"
										/>
									</div>
								)}
							</div>
						</div>
					) : (
						<RaiseNewDemand
							isPublic={true}
							backToRaiseNewDemandClicked={handleBackToRaiseNewDemandClick}
							requestedNewAccountDemand={handleNewAccountDemand}
						/>
					)
				) : (
					<Alert severity="error" className="mt-4">
						{demandRights?.message ||
							"Public demand creation is not available at this time."}
					</Alert>
				)}
			</main>
			<Footer />
		</div>
	);
}

export default PublicRaiseDemandWork;
