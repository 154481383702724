import React, { useState, useEffect, useMemo } from "react";
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Button,
	Grid,
	Box,
	TextField,
	InputAdornment,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	CircularProgress,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { fetchWorkList } from "pages/dashboard/services/getWorkList";
import { fetchSubCategories } from "pages/dashboard/services/getCategories";
import { DOMAIN_URL } from "utils/config";
import Cookies from "js-cookie";
import { getCurrentLanguage, translations } from "utils/translations";

const WorkList = ({
	onSelectWork,
	workType,
	jobCardId = "",
	setShowWorkList,
	isPublic = false,
}) => {
	const [workList, setWorkList] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [loading, setLoading] = useState(false);

	// States for filters
	const [categoryList, setCategoryList] = useState([]);
	const [subType1List, setSubType1List] = useState([]);
	const [subType2List, setSubType2List] = useState([]);
	const [subType3List, setSubType3List] = useState([]);
	const [subType4List, setSubType4List] = useState([]);

	const [category, setCategory] = useState("");
	const [subType1, setSubType1] = useState("");
	const [subType2, setSubType2] = useState("");
	const [subType3, setSubType3] = useState("");
	const [subType4, setSubType4] = useState("");

	const [selectingWorkId, setSelectingWorkId] = useState(null);

	// New state for the popup
	const [workDetailsOpen, setWorkDetailsOpen] = useState(false);
	const [selectedWorkId, setSelectedWorkId] = useState(null);
	const [workDetails, setWorkDetails] = useState({
		work_name: "",
		phone_number: "",
	});
	const [errors, setErrors] = useState({});

	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	// Fetch initial work list
	useEffect(() => {
		if (!category && !subType1 && !subType2 && !subType3 && !subType4) {
			const getWorkList = async () => {
				setLoading(true);
				try {
					const response = await fetchWorkList(workType, jobCardId);
					if (
						response.status &&
						response.results &&
						response.results.length > 0
					) {
						setWorkList(response.results);
					} else {
						setWorkList([]);
						// console.log("No work items found or invalid response");
					}
				} catch (error) {
					// console.error("Error fetching work list:", error);
					setWorkList([]);
				} finally {
					setLoading(false);
				}
			};
			getWorkList();
		}
	}, [workType, jobCardId]);

	// Fetch initial category list
	useEffect(() => {
		const fetchCategoryList = async () => {
			try {
				const response = await fetchSubCategories("");
				setCategoryList(response.results);
			} catch (error) {
				console.error("Error fetching category list:", error);
			}
		};
		fetchCategoryList();
	}, []);

	// Fetch next level subcategories
	const fetchNextLevelSubCategories = async (category_id) => {
		try {
			const response = await fetchSubCategories(category_id);
			return response.results;
		} catch (error) {
			console.error("Error fetching subcategories:", error);
			return []; // Return an empty array in case of error
		}
	};

	// Handle category selection and fetch Sub-type I
	const handleCategoryChange = async (e) => {
		const selectedCategory = e.target.value;
		setCategory(selectedCategory);
		setSubType1("");
		setSubType2("");
		setSubType3("");
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedCategory);
		setSubType1List(subCategories);
		updateWorkList(selectedCategory);
	};

	// Handle Sub-type I selection and fetch Sub-type II
	const handleSubType1Change = async (e) => {
		const selectedSubType1 = e.target.value;
		setSubType1(selectedSubType1);
		setSubType2("");
		setSubType3("");
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedSubType1);
		setSubType2List(subCategories);
		updateWorkList(category, selectedSubType1);
	};

	// Handle Sub-type II selection and fetch Sub-type III
	const handleSubType2Change = async (e) => {
		const selectedSubType2 = e.target.value;
		setSubType2(selectedSubType2);
		setSubType3("");
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedSubType2);
		setSubType3List(subCategories);
		updateWorkList(category, subType1, selectedSubType2);
	};

	// Handle Sub-type III selection and fetch Sub-type IV
	const handleSubType3Change = async (e) => {
		const selectedSubType3 = e.target.value;
		setSubType3(selectedSubType3);
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedSubType3);
		setSubType4List(subCategories);
		updateWorkList(category, subType1, subType2, selectedSubType3);
	};

	// Handle Sub-type IV selection
	const handleSubType4Change = (e) => {
		const selectedSubType4 = e.target.value;
		setSubType4(selectedSubType4);
		updateWorkList(category, subType1, subType2, subType3, selectedSubType4);
	};

	// Function to update work list based on selected filters
	const updateWorkList = async (
		work_sc1_id,
		work_sc2_id = "",
		work_sc3_id = "",
		work_sc4_id = "",
		work_sc5_id = ""
	) => {
		setLoading(true);
		try {
			const response = await fetchWorkList(workType, jobCardId, {
				work_sc1_id,
				work_sc2_id,
				work_sc3_id,
				work_sc4_id,
				work_sc5_id,
			});
			if (response.status && response.results && response.results.length > 0) {
				setWorkList(response.results);
			} else {
				setWorkList([]);
				console.log("No work items found or invalid response");
			}
		} catch (error) {
			console.error("Error updating work list:", error);
			setWorkList([]);
		} finally {
			setLoading(false);
		}
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const filteredWorkList = useMemo(() => {
		let filteredList = workList;
		if (searchTerm.length >= 2) {
			filteredList = filteredList.filter(
				(work) =>
					work.work_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
					work.description.toLowerCase().includes(searchTerm.toLowerCase())
			);
		}
		return filteredList;
	}, [workList, searchTerm]);

	const handleWorkSelection = async (workId, categories) => {
		if (selectingWorkId) return; // Prevent multiple submissions
		setSelectingWorkId(workId);

		try {
			await onSelectWork(workId, categories);
		} finally {
			setSelectingWorkId(null);
		}
	};

	// Modified handleSelectWork function
	const handleSelectWork = (workId) => {
		// Only show the dialog for logged-in users (not public users)
		if (!isPublic) {
			setSelectedWorkId(workId);
			setWorkDetailsOpen(true);
		} else {
			// For public users, directly call onSelectWork without showing dialog
			onSelectWork(workId);
		}
	};

	// Handle input changes in the dialog
	const handleWorkDetailsChange = (e) => {
		const { name, value } = e.target;
		setWorkDetails((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// Validate and submit the dialog
	const handleWorkDetailsSubmit = () => {
		// Validate fields
		const newErrors = {};
		if (!workDetails.work_name.trim()) {
			newErrors.work_name = "Work name is required";
		}

		const phoneRegex = /^\d{10}$/;
		if (
			!workDetails.phone_number.trim() ||
			!phoneRegex.test(workDetails.phone_number)
		) {
			newErrors.phone_number = "Valid 10-digit phone number is required";
		}

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		// If validation passes, close dialog and proceed with selection
		setWorkDetailsOpen(false);

		// Call the original onSelectWork with additional options
		onSelectWork(selectedWorkId, {
			work_name: workDetails.work_name,
			phone_number: workDetails.phone_number,
		});
	};

	return (
		<>
			{/* Back and Search */}
			<Box
				sx={{
					mb: 2,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Button
					variant="outlined"
					startIcon={<ArrowBackIcon />}
					onClick={() => setShowWorkList(false)}
					sx={{ mr: 2 }}
				>
					{t.back}
				</Button>
				<TextField
					variant="outlined"
					size="small"
					placeholder="Search works..."
					value={searchTerm}
					onChange={handleSearchChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Box>

			{/* Filters */}
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={6} md={3}>
					<FormControl fullWidth>
						<InputLabel>Simplified Work Category</InputLabel>
						<Select
							value={category}
							label="Simplified Work Category"
							onChange={handleCategoryChange}
							endAdornment={
								category && (
									<IconButton
										size="small"
										onClick={() =>
											handleCategoryChange({ target: { value: "" } })
										}
										sx={{ marginRight: 1 }}
									>
										<ClearIcon />
									</IconButton>
								)
							}
						>
							{categoryList.map((category) => (
								<MenuItem
									key={category.work_scategory_id}
									value={category.work_scategory_id}
								>
									{category.sub_category_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<FormControl fullWidth disabled={!category || !subType1List.length}>
						<InputLabel>Work Sub-type I</InputLabel>
						<Select
							value={subType1}
							label="Work Sub-type I"
							onChange={handleSubType1Change}
							endAdornment={
								subType1 && (
									<IconButton
										size="small"
										onClick={() =>
											handleSubType1Change({ target: { value: "" } })
										}
										sx={{ marginRight: 1 }}
									>
										<ClearIcon />
									</IconButton>
								)
							}
						>
							{subType1List.map((subType) => (
								<MenuItem
									key={subType.work_scategory_id}
									value={subType.work_scategory_id}
								>
									{subType.sub_category_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<FormControl fullWidth disabled={!subType1 || !subType2List.length}>
						<InputLabel>Work Sub-type II</InputLabel>
						<Select
							value={subType2}
							label="Work Sub-type II"
							onChange={handleSubType2Change}
							endAdornment={
								subType2 && (
									<IconButton
										size="small"
										onClick={() =>
											handleSubType2Change({ target: { value: "" } })
										}
										sx={{ marginRight: 1 }}
									>
										<ClearIcon />
									</IconButton>
								)
							}
						>
							{subType2List.map((subType) => (
								<MenuItem
									key={subType.work_scategory_id}
									value={subType.work_scategory_id}
								>
									{subType.sub_category_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<FormControl fullWidth disabled={!subType2 || !subType3List.length}>
						<InputLabel>Work Sub-type III</InputLabel>
						<Select
							value={subType3}
							label="Work Sub-type III"
							onChange={handleSubType3Change}
							endAdornment={
								subType3 && (
									<IconButton
										size="small"
										onClick={() =>
											handleSubType3Change({ target: { value: "" } })
										}
										sx={{ marginRight: 1 }}
									>
										<ClearIcon />
									</IconButton>
								)
							}
						>
							{subType3List.map((subType) => (
								<MenuItem
									key={subType.work_scategory_id}
									value={subType.work_scategory_id}
								>
									{subType.sub_category_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<FormControl fullWidth disabled={!subType3 || !subType4List.length}>
						<InputLabel>Work Sub-type IV</InputLabel>
						<Select
							value={subType4}
							label="Work Sub-type IV"
							onChange={handleSubType4Change}
							endAdornment={
								subType4 && (
									<IconButton
										size="small"
										onClick={() =>
											handleSubType4Change({ target: { value: "" } })
										}
										sx={{ marginRight: 1 }}
									>
										<ClearIcon />
									</IconButton>
								)
							}
						>
							{subType4List.map((subType) => (
								<MenuItem
									key={subType.work_scategory_id}
									value={subType.work_scategory_id}
								>
									{subType.sub_category_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			{/* Work List */}
			{loading ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "200px",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<Grid container spacing={2}>
					{filteredWorkList.map((work) => (
						<Grid item xs={12} sm={6} md={4} key={work.work_id}>
							<Card
								sx={{
									height: "100%",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<CardMedia
									component="img"
									sx={{ height: 200, width: "100%", objectFit: "cover" }}
									image={`${DOMAIN_URL}/assets/core/uploads/work/${work.work_image}`}
									alt={work.work_title}
									loading="lazy"
								/>
								<CardContent
									sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
								>
									<Typography gutterBottom variant="h5" component="div">
										{work.work_title}
									</Typography>
									<Typography
										variant="body2"
										color="text.secondary"
										sx={{ flexGrow: 1 }}
									>
										{work.description}
									</Typography>
									<Typography variant="body2" sx={{ my: 2 }}>
										Wage:
										<strong>
											{work.amount_type === "FIXED"
												? ` ₹${work.wage}`
												: ` ${work.wage}%`}
										</strong>{" "}
										| Material:{" "}
										<strong>
											{work.amount_type === "FIXED"
												? ` ₹${work.material}`
												: ` ${work.material}%`}
										</strong>
									</Typography>
									<Box
										sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
									>
										{work.visibility === "VNO" && !Cookies.get("admin_id") && (
											<Typography variant="body2" color="info">
												To select this work contact call center:{" "}
												<a
													href="tel:8277506000"
													style={{
														color: "inherit",
														textDecoration: "underline",
													}}
												>
													8277506000
												</a>
											</Typography>
										)}
										<Button
											variant="contained"
											color="primary"
											onClick={() => handleSelectWork(work.work_id)}
											disabled={
												(work.visibility === "VNO" &&
													!Cookies.get("admin_id")) ||
												selectingWorkId === work.work_id
											}
										>
											{selectingWorkId === work.work_id ? (
												<CircularProgress size={24} color="inherit" />
											) : (
												t.select
											)}
										</Button>
									</Box>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			)}

			{/* Add dialog for work details */}
			<Dialog open={workDetailsOpen} onClose={() => setWorkDetailsOpen(false)}>
				<DialogTitle>Additional Information Required</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} sx={{ mt: 1 }}>
						<Grid item xs={12}>
							<TextField
								name="work_name"
								label="Work Name"
								value={workDetails.work_name}
								onChange={handleWorkDetailsChange}
								fullWidth
								required
								error={!!errors.work_name}
								helperText={errors.work_name}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name="phone_number"
								label="Phone Number"
								value={workDetails.phone_number}
								onChange={handleWorkDetailsChange}
								fullWidth
								required
								error={!!errors.phone_number}
								helperText={errors.phone_number}
								inputProps={{ maxLength: 10 }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setWorkDetailsOpen(false)}>Cancel</Button>
					<Button
						onClick={handleWorkDetailsSubmit}
						color="primary"
						variant="contained"
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default WorkList;
