import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { fetchBudgetYears } from "pages/dashboard/api";
import Cookies from "js-cookie";

const YearSelect = () => {
	const [years, setYears] = useState([]);
	const [adminInfo, setAdminInfo] = useState({ name: "", link: "" });
	const [selectedYear, setSelectedYear] = useState(
		Cookies.get("budget_year") || ""
	);

	useEffect(() => {
		const loadYears = async () => {
			try {
				const fetchedYearsData = await fetchBudgetYears();
				setYears(fetchedYearsData.results);
				setAdminInfo({
					name: fetchedYearsData?.admin_name || "",
					link: fetchedYearsData?.admin_link || "#",
				});

				// If no year is selected and we have years, select the first one
				if (!selectedYear && fetchedYearsData.results.length > 0) {
					const defaultYear = fetchedYearsData.results[0].budget_year_id;
					setSelectedYear(defaultYear);
					Cookies.set("budget_year", defaultYear, {
						secure: true,
					});
				}
			} catch (error) {
				console.log("Error fetching budget years:", error);
			}
		};

		loadYears();
	}, [selectedYear]);

	const handleChange = (event) => {
		const value = event.target.value;
		setSelectedYear(value);
		Cookies.set("budget_year", value, {
			secure: true,
		});
		window.location.reload();
	};

	return (
		<div className="flex items-center gap-4">
			{adminInfo.name && (
				<>
					<a
						href={adminInfo.link}
						target="_blank"
						rel="noopener noreferrer"
						className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md text-gray-400 dark:text-white/70 border border-gray-400 dark:border-white/70 hover:text-gray-600 dark:hover:text-white transition-colors"
					>
						{adminInfo.name}
					</a>
					<span className="text-gray-400 dark:text-gray-500">|</span>
				</>
			)}
			<FormControl className="w-full sm:w-48">
				<InputLabel
					id="year-select-label"
					className="text-sm text-gray-400 dark:text-white/70 w-full xs:w-auto"
					sx={{
						lineHeight: "1rem",
						transform: "translate(14px, 9px) scale(1)",
						"&.Mui-focused, &.MuiInputLabel-shrink": {
							transform: "translate(14px, -9px) scale(0.75)",
						},
						width: "100%",
						"@media (min-width: 480px)": {
							width: "auto",
						},
					}}
				>
					Year
				</InputLabel>
				<Select
					labelId="year-select-label"
					id="year-select"
					value={selectedYear}
					label="Year"
					onChange={handleChange}
					className="h-9 text-sm bg-transparent text-gray-400 dark:text-white/70 border-gray-400 dark:border-white/70"
					sx={{
						"& .MuiSelect-select": {
							paddingTop: "8px",
							paddingBottom: "8px",
						},
						"& .MuiOutlinedInput-notchedOutline": {
							top: "-5px",
							borderColor: "currentColor",
						},
						"&:hover .MuiOutlinedInput-notchedOutline": {
							borderColor: "currentColor",
						},
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: "currentColor",
						},
						"& .MuiSvgIcon-root": {
							color: "currentColor",
						},
					}}
					MenuProps={{
						PaperProps: {
							style: { maxHeight: 200 },
							className:
								"bg-white dark:bg-slate-900 text-gray-900 dark:text-white",
						},
					}}
				>
					<MenuItem value="" disabled>
						Select Year
					</MenuItem>
					{years.map((yearObj) => (
						<MenuItem
							key={yearObj.budget_year_id}
							value={yearObj.budget_year_id}
							className="text-sm"
						>
							{yearObj.budget_year}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default YearSelect;
