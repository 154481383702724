import React, { useState } from "react";
import { TextField, Button, Grid, Typography, Alert } from "@mui/material";
import { isMobile } from "react-device-detect";

const PhotoUpload = ({
	onPhotoChange,
	error,
	disabled,
	helperText,
	label = "Upload Photo",
	required = true,
}) => {
	const [fileTypeError, setFileTypeError] = useState(false);

	// Function to validate file type
	const handleFileChange = (event) => {
		const file = event.target.files[0];

		// Clear the file type error when user attempts a new upload
		setFileTypeError(false);

		if (file) {
			const validTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!validTypes.includes(file.type)) {
				// Set file type error
				setFileTypeError(true);

				// Reset the input value
				event.target.value = "";

				// Call onPhotoChange with a special error flag
				onPhotoChange({
					target: {
						files: [],
						error:
							"Invalid file type. Only JPG, JPEG, and PNG files are allowed.",
					},
				});
				return;
			}
			onPhotoChange(event);
		}
	};

	const displayError = fileTypeError
		? "Invalid file type. Only JPG, JPEG, and PNG files are allowed."
		: error;

	const helperMessage = "Only .jpg, .jpeg, and .png files are allowed";

	if (isMobile) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="body2" className="text-gray-600 mb-2">
						Take a photo:
					</Typography>
					<Typography
						variant="caption"
						color="textSecondary"
						className="block mb-2"
					>
						{helperMessage}
					</Typography>
				</Grid>
				{fileTypeError && (
					<Grid item xs={12}>
						<Alert severity="error" sx={{ mb: 2 }}>
							Invalid file type. Only JPG, JPEG, and PNG files are allowed.
						</Alert>
					</Grid>
				)}
				<Grid item xs={12}>
					<TextField
						type="file"
						accept="image/jpeg,image/jpg,image/png"
						onChange={handleFileChange}
						fullWidth
						error={!!displayError}
						helperText={displayError}
						inputProps={{
							capture: "environment",
							style: { display: "none" },
						}}
						disabled={disabled}
						sx={{
							"& .MuiOutlinedInput-root": {
								padding: 0,
								border: "none",
								"& fieldset": { border: "none" },
							},
						}}
						InputProps={{
							startAdornment: (
								<Button
									component="label"
									variant="contained"
									fullWidth
									disabled={disabled}
									className="bg-red-500 hover:bg-red-600 text-white w-full normal-case rounded-md py-2"
								>
									Use Camera
									<input
										type="file"
										accept="image/jpeg,image/jpg,image/png"
										capture="environment"
										hidden
										onChange={handleFileChange}
									/>
								</Button>
							),
						}}
					/>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<>
				{fileTypeError && (
					<Alert severity="error" sx={{ mb: 2 }}>
						Invalid file type. Only JPG, JPEG, and PNG files are allowed.
					</Alert>
				)}
				<TextField
					type="file"
					accept="image/jpeg,image/jpg,image/png"
					onChange={handleFileChange}
					error={!!displayError}
					helperText={displayError || helperMessage}
					inputProps={{
						style: { display: "none" },
					}}
					disabled={disabled}
					sx={{
						"& .MuiOutlinedInput-root": {
							padding: 0,
							border: "none",
							"& fieldset": { border: "none" },
						},
					}}
					InputProps={{
						startAdornment: (
							<Button
								component="label"
								variant="contained"
								disabled={disabled}
								className="bg-red-500 hover:bg-red-600 text-white normal-case rounded-md py-2"
							>
								Choose Photo
								<input
									type="file"
									accept="image/jpeg,image/jpg,image/png"
									hidden
									onChange={handleFileChange}
								/>
							</Button>
						),
					}}
				/>
			</>
		);
	}
};

export default PhotoUpload;
