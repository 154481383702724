import { DOMAIN_URL, getAuthHeaders } from "./config";

export const viewHtmlReport = async (endpoint, id) => {
	try {
		const response = await fetch(`${DOMAIN_URL}/${endpoint}/${id}`, {
			method: "GET",
			headers: getAuthHeaders(),
		});

		if (!response.ok) throw new Error(`Failed to fetch ${endpoint}`);

		const data = await response.json();
		if (data.status) {
			const newWindow = window.open("", "_blank");
			newWindow.document.write(data.results);
			newWindow.document.close();
			return true;
		}
		return false;
	} catch (error) {
		console.error(`Error fetching ${endpoint}:`, error);
		return false;
	}
};
