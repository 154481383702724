import React, { useState, useEffect } from "react";
import RaiseNewDemand from "component/RaiseNewDemand";
import { Alert, CircularProgress, Container } from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";

function RaiseDemandWork() {
	const [loading, setLoading] = useState(true);
	const [demandRights, setDemandRights] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const checkDemandRights = async () => {
			try {
				const response = await fetch(`${DOMAIN_URL}/check-demand-rights`, {
					method: "GET",
					headers: getAuthHeaders(),
				});

				if (!response.ok) {
					throw new Error("Failed to fetch demand rights");
				}

				const data = await response.json();
				setDemandRights(data);
			} catch (err) {
				console.error("Error checking demand rights:", err);
				setError("Failed to load page. Please try again later.");
			} finally {
				setLoading(false);
			}
		};

		checkDemandRights();
	}, []);

	return (
		<div className="flex flex-col">
			{loading ? (
				<div className="flex justify-center items-center h-64">
					<CircularProgress />
				</div>
			) : error ? (
				<Alert severity="error" className="mt-4">
					{error}
				</Alert>
			) : demandRights && demandRights.results.pdo_demand === "1" ? (
				<RaiseNewDemand />
			) : (
				<Alert severity="error" className="mt-4">
					{demandRights?.message ||
						"Demand creation is not available at this time."}
				</Alert>
			)}
		</div>
	);
}

export default RaiseDemandWork;
