import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import { CheckUserResult } from "./types";
import Cookies from "js-cookie";

export async function checkUserExists(
	phoneNumber: string,
	password: string
): Promise<CheckUserResult> {
	try {
		const otpCache = Cookies.get(`otpCache_${phoneNumber}`);
		const otpFlag = !otpCache;

		const inputData = JSON.stringify({
			user_name: phoneNumber,
			admin_password: password,
			otp_flag: otpFlag,
		});

		const params = new URLSearchParams();
		params.append("input_data", inputData);
		const response = await fetch(`${DOMAIN_URL}/login`, {
			method: "POST",
			headers: getAuthHeaders(),
			body: params,
		});

		const data = await response.json();
		// Return the API response directly
		return data;
	} catch (error) {
		console.error("Error checking user existence:", error);
		return {
			status: false,
			new_user: false,
			message: "An error occurred while checking user existence",
		};
	}
}

export async function getUserInfo(
	adminId?: string,
	roleId?: string,
	budgetYear?: string
): Promise<any> {
	if (!adminId) {
		adminId = Cookies.get("admin_id");
	}

	try {
		const response = await fetch(`${DOMAIN_URL}/admin-info/${adminId}`, {
			method: "GET",
			headers: getAuthHeaders(false, adminId, roleId, budgetYear),
		});

		const data = await response.json();

		if (response.ok) {
			return data;
		} else {
			console.log("Failed to fetch user info:", data.message);
			return {
				success: false,
				message: data.message || "Failed to fetch user info",
			};
		}
	} catch (error) {
		console.log("Error fetching user info:", error);
		return {
			success: false,
			message: "An error occurred while fetching user info",
		};
	}
}

export async function generateOtp(
	phoneNumber: string,
	new_user: string
): Promise<boolean> {
	try {
		const inputData = JSON.stringify({
			user_name: phoneNumber,
			new_user: new_user,
		});

		const params = new URLSearchParams();
		params.append("input_data", inputData);

		const response = await fetch(`${DOMAIN_URL}/generate-otp`, {
			method: "POST",
			headers: getAuthHeaders(),
			body: params,
		});

		const data = await response.json();
		if (data.status) {
			return data;
		} else {
			console.error("OTP generation failed:", data.message);
			return data;
		}
	} catch (error) {
		console.error("Error generating OTP:", error);
		return false;
	}
}

export async function verifyOtp(
	phoneNumber: string,
	otp: string,
	new_user: string
): Promise<any> {
	try {
		const inputData = JSON.stringify({
			user_name: phoneNumber,
			otp_value: otp,
			new_user: new_user,
		});

		const params = new URLSearchParams();
		params.append("input_data", inputData);

		const response = await fetch(`${DOMAIN_URL}/verify-otp`, {
			method: "POST",
			headers: getAuthHeaders(),
			body: params,
		});

		const data = await response.json();

		if (data.status) {
			if (data.data?.admin_id) {
				Cookies.set(`otpCache_${phoneNumber}`, data.data.admin_id, {
					expires: 3, // 1/24 if you want 1 hour
					secure: true,
				});
			}

			if (data?.user_name) {
				Cookies.set(`otpCache_${phoneNumber}`, data.user_name, {
					expires: 3, // 1/24 if you want 1 hour
					secure: true,
				});
			}

			return data as CheckUserResult;
		} else {
			// OTP verification failed
			return data;
		}
	} catch (error) {
		console.error("Error verifying OTP:", error);
		return error;
	}
}

export async function login(userData: CheckUserResult): Promise<boolean> {
	try {
		// Store the necessary authentication details in cookies or local storage
		// Cookies.set("authToken", userData.data.Authenticationkey, {
		// 	expires: 1 / 24,
		// }); // 1 hour
		// Cookies.set("userData", JSON.stringify(userData), { expires: 7 });

		if (userData.data?.admin_id) {
			Cookies.set("admin_id", userData.data.admin_id, {
				expires: 3,
				// httpOnly: true,
				secure: true,
			}); // 1 days
		}
		if (userData.data?.role_id) {
			Cookies.set("role_id", userData.data.role_id, {
				expires: 3,
				secure: true,
			});
		}
		if (userData.data?.budget_year) {
			Cookies.set("budget_year", userData.data.budget_year, {
				expires: 3,
				secure: true,
			});
		}
		if (userData.data?.Authenticationkey) {
			Cookies.set(
				"Authenticationkey",
				JSON.stringify(userData.data.Authenticationkey),
				{
					expires: 3,
					secure: true,
				}
			);
		}

		// Optionally, make a call to your backend to store the session or trigger other side effects
		// Example:
		// await fetch(`${DOMAIN_URL}/api/session`, {
		//     method: "POST",
		//     headers: getAuthHeaders(),
		//     body: JSON.stringify({ userId: userData.admin_id }),
		// });

		return true;
	} catch (error) {
		console.error("Login error:", error);
		return false;
	}
}

export async function register(registrationData: {
	first_name: string;
	middle_name: string;
	last_name: string;
	email_address: string;
	phone_number: string;
	user_name: string;
	admin_password: string;
	state_id: string;
	zp_id: string;
	tp_id: string;
	gp_id: string;
	role_id: string;
	designation_name: string;
}): Promise<{ success: boolean; message: string; data?: any }> {
	try {
		const inputData = JSON.stringify(registrationData);

		const params = new URLSearchParams();
		params.append("input_data", inputData);

		const response = await fetch(`${DOMAIN_URL}/register`, {
			method: "POST",
			headers: getAuthHeaders(),
			body: params,
		});

		const responseData = await response.json();

		if (responseData.status) {
			return {
				success: responseData.status,
				message: responseData.message,
				data: {
					admin_details: responseData.data?.admin_details || null,
				},
			};
		} else {
			return {
				success: false,
				message: responseData.message || "Registration failed",
			};
		}
	} catch (error) {
		console.error("Error during registration:", error);
		return {
			success: false,
			message: "An error occurred during registration",
		};
	}
}
