import { useState } from "react";
import { Typography, CircularProgress, Alert } from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import LocationSelector from "./LocationSelector";
import WorksTable from "./WorksTable";

const ApprovedWorkList = () => {
	const [approvedWorks, setApprovedWorks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pageTitle, setPageTitle] = useState("");
	const [error, setError] = useState("");
	const [currentGpId, setCurrentGpId] = useState(null);

	const handleGPSelect = async (gpId) => {
		if (!gpId) return;

		setIsLoading(true);
		setError("");
		try {
			const response = await fetch(
				`${DOMAIN_URL}/state-approved-work/${gpId}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);

			const data = await response.json();
			setPageTitle(data.key);

			if (data.status) {
				setApprovedWorks(data.results);
				setCurrentGpId(gpId);
			} else {
				setApprovedWorks([]);
				setError("No approved works available for this location");
			}
		} catch (error) {
			console.error("Error fetching approved works:", error);
			setError("Failed to fetch approved works");
		} finally {
			setIsLoading(false);
		}
	};

	const refreshData = () => {
		handleGPSelect(currentGpId);
	};

	return (
		<>
			<Typography
				variant="h6"
				component="div"
				className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-4 xs:pt-2 pt-1 pb-2"
			>
				{pageTitle || "Approved Work List"}
			</Typography>

			<LocationSelector onGPSelect={handleGPSelect} />

			{isLoading ? (
				<div className="flex justify-center items-center h-64">
					<CircularProgress />
				</div>
			) : error ? (
				<Alert severity="info" className="mt-4">
					{error}
				</Alert>
			) : approvedWorks.length > 0 ? (
				<WorksTable data={approvedWorks} onDataUpdate={refreshData} />
			) : null}
		</>
	);
};

export default ApprovedWorkList;
